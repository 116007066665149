"use client"

import { useState, useRef } from "react"
import "../CustomCSS/AccidentPage.css"
import Layout from "../Layout/Layout"
import {
    Button,
    TextField,
    FormControl,
    FormControlLabel,
    RadioGroup,
    Radio,
    Select,
    MenuItem,
    InputLabel,
    Checkbox,
    Paper,
    Stepper,
    Step,
    StepLabel,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@mui/material"
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import {
    handleEmailChange,
    handleNumbersChange,
    handleAphabetsChange
} from "../../CommonJquery/CommonJquery"
import dayjs from 'dayjs';


import SignatureCanvas from "react-signature-canvas";

export default function AccidentPage() {
    const [activeStep, setActiveStep] = useState(0)
    const signaturePad = useRef(null);
    const [formData, setFormData] = useState({
        // Driver Information
        driverName: "",
        address: "",
        city: "",
        state: "",
        zip: "",
        homePhone: "",
        workPhone: "",
        dlNumber: "",
        email: "",
        carPurpose: "",
        carDamage: "",
        occupants: "",

        // Other Car Information
        otherCarMake: "",
        otherCarYear: "",
        otherCarBodyType: "",
        otherCarLicensePlate: "",
        otherCarOwner: "",
        otherCarOwnerAddress: "",
        otherCarOwnerCity: "",
        otherCarOwnerState: "",
        otherCarOwnerZip: "",
        otherCarDriverName: "",
        otherCarDriverPhone: "",
        otherCarDriverAddress: "",
        otherCarDriverCity: "",
        otherCarDriverState: "",
        otherCarDriverZip: "",
        otherCarDamage: "",
        otherCarDrivable: "",
        otherDriverInsurance: "",
        otherCarOccupants: "",

        // Time, Place and Facts
        accidentDate: null,
        accidentTime: "",
        timeOfDay: "daylight",
        accidentLocation: "",
        accidentCity: "",
        accidentState: "",
        roadType: "",
        roadCondition: "dry",
        weather: "",
        yourDirection: "",
        yourStreet: "",
        otherDriverDirection: "",
        otherDriverStreet: "",
        yourSignOrSignal: "",
        otherDriverSignOrSignal: "",
        failedToObserve: "",
        otherDriverFailedToObserve: "",
        firstVehicle: "",
        yourSignals: "",
        otherDriverSignals: "",
        youDrinking: "",
        otherDriverDrinking: "",
        ticketed: "",
        violation: "",
        admitBlame: "",
        otherDriverAdmitBlame: "",
        reportedToPolice: false,
        policeDept: "",
        policeReportNumber: "",
        Date: null,

        // Witnesses and Injuries
        witnesses: [{ name: "", age: "", address: "", phone: "" }],
        anyoneInjured: "no",
        injuries: [{ name: "", age: "", sex: "", address: "", injuryType: "", vehicle: "" }],
        whereTaken: "",
        doctor: "",
        hospitalAddress: "",
        accidentDescription: "",
        // Image Uploads
        yourCarImages: [],
        otherCarImages: [],
        policeReportImages: [],
        additionalNotes: "",
    })
    const [signatureData, setSignatureData] = useState("");
    const [signatureError, setSignatureError] = useState(false);
    const [Accident_date, setAccident_date] = useState("");
    const [selectedDate, setSelectedDate] = useState("");

    const handleChange = (e) => {
        const { name, value } = e.target
        setFormData({
            ...formData,
            [name]: value,
        })
    }

    const handleCheckboxChange = (e) => {
        const { name, checked } = e.target
        setFormData({
            ...formData,
            [name]: checked,
        })
    }

    const handleWitnessChange = (index, e) => {
        const { name, value } = e.target
        const witnesses = [...formData.witnesses]
        witnesses[index] = {
            ...witnesses[index],
            [name.split("-")[1]]: value,
        }
        setFormData({
            ...formData,
            witnesses,
        })
    }
    const handleWitnessChangeAge = (index, e) => {
        const { name, value } = e.target
        if (value.length > 2) return;
        const witnesses = [...formData.witnesses]
        witnesses[index] = {
            ...witnesses[index],
            [name.split("-")[1]]: value,
        }
        setFormData({
            ...formData,
            witnesses,
        })
    }

    const addWitness = () => {
        setFormData({
            ...formData,
            witnesses: [...formData.witnesses, { name: "", age: "", address: "", phone: "" }],
        })
    }

    const handleInjuryChange = (index, e) => {
        const { name, value } = e.target
        const injuries = [...formData.injuries]
        injuries[index] = {
            ...injuries[index],
            [name.split("-")[1]]: value,
        }
        setFormData({
            ...formData,
            injuries,
        })
    }
    const handleInjuryChangeAge = (index, e) => {
        const { name, value } = e.target
        if (value.length > 2) return;
        const injuries = [...formData.injuries]
        injuries[index] = {
            ...injuries[index],
            [name.split("-")[1]]: value,
        }
        setFormData({
            ...formData,
            injuries,
        })
    }

    const addInjury = () => {
        setFormData({
            ...formData,
            injuries: [...formData.injuries, { name: "", age: "", sex: "", address: "", injuryType: "", vehicle: "" }],
        })
    }

    const [openDatePickerAccident, setOpenDatePickerAccident] = useState(false);
    const [openDatePickerAccident2, setOpenDatePickerAccident2] = useState(false);

    const handleDatePickerOpen = () => {
        setOpenDatePickerAccident(!openDatePickerAccident);
    };
    const handleDatePickerOpen2 = () => {
        setOpenDatePickerAccident2(!openDatePickerAccident2);
    };

    const handleDatePickerClose = () => {
        setOpenDatePickerAccident(false);
    };
    const handleDatePickerClose2 = () => {
        setOpenDatePickerAccident2(false);
    };

    // const handleDateChange = (newValue) => {
    //     setFormData({
    //         ...formData,
    //         accidentDate: newValue,
    //     });
    //     setOpenDatePickerAccident(false);
    // };

    const handleDateChange = (newDate) => {
        if (newDate) {
            const formattedDate = dayjs(newDate).format("DD/MM/YYYY");
            setAccident_date(formattedDate);
        }
        setOpenDatePickerAccident(false);
    };
    console.log("Selected Accident_date Date:", Accident_date);
    const handleDateChange2 = (newDate) => {
        if (newDate) {
            const formattedDate = dayjs(newDate).format("DD/MM/YYYY");
            setSelectedDate(formattedDate);
        }
        setOpenDatePickerAccident2(false);
    };
    console.log("Selected Date:", selectedDate);


    const MAX_IMAGES = 5;
    const handleFileChange = (e, field) => {
        const newFiles = Array.from(e.target.files);
        const existingFiles = formData[field];

        if (existingFiles.length + newFiles.length > MAX_IMAGES) {
            alert(`You can only upload a maximum of ${MAX_IMAGES} images.`);
            return;
        }

        setFormData({
            ...formData,
            [field]: [...existingFiles, ...newFiles],
        });
    };
    const removeFile = (field, index) => {
        const updatedFiles = [...formData[field]]
        updatedFiles.splice(index, 1)
        setFormData({
            ...formData,
            [field]: updatedFiles,
        })
    }
    const handleDragOver = (event) => {
        event.preventDefault();
    };

    const handleDrop = (event, fieldName) => {
        event.preventDefault();

        const droppedFiles = Array.from(event.dataTransfer.files);
        const imageFiles = droppedFiles.filter(file => file.type.startsWith("image/"));
        const nonImageFiles = droppedFiles.filter(file => !file.type.startsWith("image/"));

        if (nonImageFiles.length > 0) {
            alert("Please drag and drop image files only (e.g., .jpg, .png). Other file types like PDF or DOC are not allowed.");
            return;
        }

        const existingFiles = formData[fieldName];

        if (existingFiles.length + imageFiles.length > MAX_IMAGES) {
            alert(`You can only upload a maximum of ${MAX_IMAGES} images.`);
            return;
        }

        setFormData({
            ...formData,
            [fieldName]: [...existingFiles, ...imageFiles],
        });
    };
    const stepRefs = useRef([]);

    const scrollToStep = (index) => {
        const isMobile = window.innerWidth <= 601;
        if (isMobile && stepRefs.current[index]) {
            stepRefs.current[index].scrollIntoView({ behavior: "smooth", inline: "center", block: "nearest" });
        }
    };

    const handleNext = () => {
        if (activeStep === 3) {
            if (signaturePad.current && signaturePad.current.isEmpty()) {
                setSignatureError(true);
                return;
            } else {
                const signatureURL = signaturePad.current.getTrimmedCanvas().toDataURL("image/png");
                setSignatureData(signatureURL);
                console.log("Signature Saved Path:", signatureURL);
                setSignatureError(false);
            }
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        const newStep = activeStep - 1;
        setActiveStep(newStep);
        scrollToStep(newStep);
    };
    const steps = [
        "Driver Information",
        "Other Car Description",
        "Time, Place and Facts",
        "Witnesses and Injuries",
        "Upload Images",
    ]

    const getStepContent = (step) => {
        switch (step) {
            case 0:
                return (
                    <div className="form-step">
                        <h3 className="stepHead">Driver Information</h3>
                        <div className="row mb-3">
                            <div className="col-md-6">
                                <TextField
                                    label="Driver Name"
                                    name="driverName"
                                    value={formData.driverName}
                                    onChange={handleChange}
                                    fullWidth
                                    className="form-control mb-3"
                                    onInput={handleAphabetsChange}
                                />
                            </div>
                            <div className="col-md-6">
                                <TextField
                                    label="Address"
                                    name="address"
                                    value={formData.address}
                                    onChange={handleChange}
                                    fullWidth
                                    className="form-control mb-3"
                                />
                            </div>
                        </div>
                        <div className="row mb-3">
                            <div className="col-md-4">
                                <TextField
                                    label="City"
                                    name="city"
                                    value={formData.city}
                                    onChange={handleChange}
                                    fullWidth
                                    className="form-control mb-3"

                                />
                            </div>
                            <div className="col-md-4">
                                <TextField
                                    label="State"
                                    name="state"
                                    value={formData.state}
                                    onChange={handleChange}
                                    fullWidth
                                    className="form-control mb-3"
                                    onInput={handleAphabetsChange}
                                />
                            </div>
                            <div className="col-md-4">
                                <TextField
                                    label="Zip"
                                    name="zip"
                                    value={formData.zip}
                                    onChange={handleChange}
                                    fullWidth
                                    className="form-control mb-3"

                                />
                            </div>
                        </div>
                        <div className="row mb-3">
                            <div className="col-md-6">
                                <TextField
                                    label="Home Phone #"
                                    name="homePhone"
                                    value={formData.homePhone}
                                    onChange={handleChange}
                                    fullWidth
                                    className="form-control mb-3"
                                    onInput={handleNumbersChange}
                                />
                            </div>
                            <div className="col-md-6">
                                <TextField
                                    label="Work Phone #"
                                    name="workPhone"
                                    value={formData.workPhone}
                                    onChange={handleChange}
                                    fullWidth
                                    className="form-control mb-3"
                                    onInput={handleNumbersChange}
                                />
                            </div>
                        </div>
                        <div className="row mb-3">
                            <div className="col-md-6">
                                <TextField
                                    label="DL #"
                                    name="dlNumber"
                                    value={formData.dlNumber}
                                    onChange={handleChange}
                                    fullWidth
                                    className="form-control mb-3"
                                />
                            </div>
                            <div className="col-md-6">
                                <TextField
                                    label="Email Address"
                                    name="email"
                                    type="email"
                                    value={formData.email}
                                    onChange={handleChange}
                                    fullWidth
                                    className="form-control mb-3"
                                    onInput={handleEmailChange}
                                />
                            </div>
                        </div>
                        <div className="row mb-3">
                            <div className="col-12">
                                <TextField
                                    label="For What purpose was car used"
                                    name="carPurpose"
                                    value={formData.carPurpose}
                                    onChange={handleChange}
                                    fullWidth
                                    className="form-control mb-3"
                                />
                            </div>
                        </div>
                        <div className="row mb-3">
                            <div className="col-12">
                                <TextField
                                    label="Describe the Damage to your car"
                                    name="carDamage"
                                    value={formData.carDamage}
                                    onChange={handleChange}
                                    fullWidth
                                    multiline
                                    rows={3}
                                    className="form-control mb-3"
                                />
                            </div>
                        </div>
                        <div className="row mb-3">
                            <div className="col-md-6">
                                <TextField
                                    label="Including you, How many occupants were in the car"
                                    name="occupants"
                                    type="number"
                                    value={formData.occupants}
                                    onChange={handleChange}
                                    fullWidth
                                    className="form-control mb-3"
                                />
                            </div>
                        </div>
                    </div>
                )
            case 1:
                return (
                    <div className="form-step">
                        <h3 className="stepHead">Description of Other Car</h3>
                        <div className="row mb-3">
                            <div className="col-md-4">
                                <TextField
                                    label="Make of other car"
                                    name="otherCarMake"
                                    value={formData.otherCarMake}
                                    onChange={handleChange}
                                    fullWidth
                                    className="form-control mb-3"
                                />
                            </div>
                            <div className="col-md-4">
                                <TextField
                                    label="Year"
                                    name="otherCarYear"
                                    value={formData.otherCarYear}
                                    onChange={handleChange}
                                    fullWidth
                                    className="form-control mb-3"
                                    maxLength={4}
                                    onInput={handleNumbersChange}

                                />
                            </div>
                            <div className="col-md-4">
                                <TextField
                                    label="Body Type"
                                    name="otherCarBodyType"
                                    value={formData.otherCarBodyType}
                                    onChange={handleChange}
                                    fullWidth
                                    className="form-control mb-3"
                                />
                            </div>
                        </div>
                        <div className="row mb-3">
                            <div className="col-md-6">
                                <TextField
                                    label="License Plate No"
                                    name="otherCarLicensePlate"
                                    value={formData.otherCarLicensePlate}
                                    onChange={handleChange}
                                    fullWidth
                                    className="form-control mb-3"
                                />
                            </div>
                            <div className="col-md-6">
                                <TextField
                                    label="Owner"
                                    name="otherCarOwner"
                                    value={formData.otherCarOwner}
                                    onChange={handleChange}
                                    fullWidth
                                    className="form-control mb-3"
                                />
                            </div>
                        </div>
                        <div className="row mb-3">
                            <div className="col-12">
                                <TextField
                                    label="Address"
                                    name="otherCarOwnerAddress"
                                    value={formData.otherCarOwnerAddress}
                                    onChange={handleChange}
                                    fullWidth
                                    className="form-control mb-3"
                                />
                            </div>
                        </div>
                        <div className="row mb-3">
                            <div className="col-md-4">
                                <TextField
                                    label="City"
                                    name="otherCarOwnerCity"
                                    value={formData.otherCarOwnerCity}
                                    onChange={handleChange}
                                    fullWidth
                                    className="form-control mb-3"
                                />
                            </div>
                            <div className="col-md-4">
                                <TextField
                                    label="State"
                                    name="otherCarOwnerState"
                                    value={formData.otherCarOwnerState}
                                    onChange={handleChange}
                                    fullWidth
                                    className="form-control mb-3"
                                />
                            </div>
                            <div className="col-md-4">
                                <TextField
                                    label="Zip"
                                    name="otherCarOwnerZip"
                                    value={formData.otherCarOwnerZip}
                                    onChange={handleChange}
                                    fullWidth
                                    className="form-control mb-3"
                                />
                            </div>
                        </div>
                        <div className="row mb-3">
                            <div className="col-md-6">
                                <TextField
                                    label="Driver Name"
                                    name="otherCarDriverName"
                                    value={formData.otherCarDriverName}
                                    onChange={handleChange}
                                    fullWidth
                                    className="form-control mb-3"
                                />
                            </div>
                            <div className="col-md-6">
                                <TextField
                                    label="Driver Phone No"
                                    name="otherCarDriverPhone"
                                    value={formData.otherCarDriverPhone}
                                    onChange={handleChange}
                                    fullWidth
                                    className="form-control mb-3"
                                />
                            </div>
                        </div>
                        <div className="row mb-3">
                            <div className="col-12">
                                <TextField
                                    label="Driver Address"
                                    name="otherCarDriverAddress"
                                    value={formData.otherCarDriverAddress}
                                    onChange={handleChange}
                                    fullWidth
                                    className="form-control mb-3"
                                />
                            </div>
                        </div>
                        <div className="row mb-3">
                            <div className="col-md-4">
                                <TextField
                                    label="City"
                                    name="otherCarDriverCity"
                                    value={formData.otherCarDriverCity}
                                    onChange={handleChange}
                                    fullWidth
                                    className="form-control mb-3"
                                />
                            </div>
                            <div className="col-md-4">
                                <TextField
                                    label="State"
                                    name="otherCarDriverState"
                                    value={formData.otherCarDriverState}
                                    onChange={handleChange}
                                    fullWidth
                                    className="form-control mb-3"
                                />
                            </div>
                            <div className="col-md-4">
                                <TextField
                                    label="Zip"
                                    name="otherCarDriverZip"
                                    value={formData.otherCarDriverZip}
                                    onChange={handleChange}
                                    fullWidth
                                    className="form-control mb-3"
                                />
                            </div>
                        </div>
                        <div className="row mb-3">
                            <div className="col-12">
                                <TextField
                                    label="Describe Damage to other car"
                                    name="otherCarDamage"
                                    value={formData.otherCarDamage}
                                    onChange={handleChange}
                                    fullWidth
                                    multiline
                                    rows={3}
                                    className="form-control mb-3"
                                />
                            </div>
                        </div>
                        <div className="row mb-3">
                            <div className="col-md-6">
                                <FormControl fullWidth className="mb-3">
                                    <InputLabel>Was other car Drivable</InputLabel>
                                    <Select
                                        name="otherCarDrivable"
                                        value={formData.otherCarDrivable}
                                        onChange={handleChange}
                                        label="Was other car Drivable"
                                    >
                                        <MenuItem value="yes">Yes</MenuItem>
                                        <MenuItem value="no">No</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                            <div className="col-md-6">
                                <TextField
                                    label="Other driver's insurance company and claim/policy number"
                                    name="otherDriverInsurance"
                                    value={formData.otherDriverInsurance}
                                    onChange={handleChange}
                                    fullWidth
                                    className="form-control mb-3"
                                />
                            </div>
                        </div>
                        <div className="row mb-3">
                            <div className="col-md-6">
                                <TextField
                                    label="How many occupants in the other car"
                                    name="otherCarOccupants"
                                    type="number"
                                    value={formData.otherCarOccupants}
                                    onChange={handleChange}
                                    fullWidth
                                    className="form-control mb-3"
                                />
                            </div>
                        </div>
                    </div>
                )
            case 2:
                return (
                    <div className="form-step">
                        <h3 className="stepHead">Time, Place and other Facts</h3>
                        <div className="row mb-3">
                            <div className="col-md-4 date_accidnet_pick" >
                                <LocalizationProvider dateAdapter={AdapterDayjs} onClick={handleDatePickerOpen}>
                                    <DatePicker
                                        label="Date of accident"

                                        value={Accident_date ? dayjs(Accident_date, "DD/MM/YYYY") : null}
                                        onChange={handleDateChange}
                                        format="DD/MM/YYYY"
                                        open={openDatePickerAccident}
                                        onClose={handleDatePickerClose}
                                        onOpen={handleDatePickerOpen}
                                        slotProps={{
                                            textField: {
                                                fullWidth: true,
                                                className: "form-control mb-3",
                                                placeholder: "DD/MM/YYYY",
                                                onClick: handleDatePickerOpen
                                            }
                                        }}
                                    />
                                </LocalizationProvider>
                            </div>
                            <div className="col-md-4">
                                <div className="d-flex align-items-center">
                                    <TextField
                                        label="Time"
                                        name="accidentTime"
                                        type="time"
                                        value={formData.accidentTime}
                                        onChange={handleChange}
                                        fullWidth
                                        InputLabelProps={{ shrink: true }}
                                        className="form-control mb-3 me-3"
                                    />

                                </div>
                            </div>
                            <div className="col-md-4">
                                <FormControl component="fieldset" className="mb-3">
                                    <RadioGroup row name="timeOfDay" value={formData.timeOfDay} onChange={handleChange}>
                                        <FormControlLabel value="daylight" control={<Radio />} label="Daylight" />
                                        <FormControlLabel value="dark" control={<Radio />} label="Dark" />
                                    </RadioGroup>
                                </FormControl>
                            </div>
                        </div>
                        <div className="row mb-3">
                            <div className="col-md-4">
                                <TextField
                                    label="Location"
                                    name="accidentLocation"
                                    value={formData.accidentLocation}
                                    onChange={handleChange}
                                    fullWidth
                                    className="form-control mb-3"
                                />
                            </div>
                            <div className="col-md-4">
                                <TextField
                                    label="City"
                                    name="accidentCity"
                                    value={formData.accidentCity}
                                    onChange={handleChange}
                                    fullWidth
                                    className="form-control mb-3"
                                />
                            </div>
                            <div className="col-md-4">
                                <TextField
                                    label="State"
                                    name="accidentState"
                                    value={formData.accidentState}
                                    onChange={handleChange}
                                    fullWidth
                                    className="form-control mb-3"
                                />
                            </div>
                        </div>
                        <div className="row mb-3">
                            <div className="col-md-4">
                                <TextField
                                    label="Type of Road"
                                    name="roadType"
                                    value={formData.roadType}
                                    onChange={handleChange}
                                    fullWidth
                                    className="form-control mb-3"
                                />
                            </div>
                            <div className="col-md-4">
                                <FormControl fullWidth className="mb-3">
                                    <InputLabel>Road Condition</InputLabel>
                                    <Select
                                        name="roadCondition"
                                        value={formData.roadCondition}
                                        onChange={handleChange}
                                        label="Road Condition"
                                    >
                                        <MenuItem value="wet">Wet</MenuItem>
                                        <MenuItem value="dry">Dry</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                            <div className="col-md-4">
                                <TextField
                                    label="Weather"
                                    name="weather"
                                    value={formData.weather}
                                    onChange={handleChange}
                                    fullWidth
                                    className="form-control mb-3"
                                />
                            </div>
                        </div>
                        <div className="row mb-3">
                            <div className="col-md-6">
                                <TextField
                                    label="Your Direction"
                                    name="yourDirection"
                                    value={formData.yourDirection}
                                    onChange={handleChange}
                                    fullWidth
                                    className="form-control mb-3"
                                />
                            </div>
                            <div className="col-md-6">
                                <TextField
                                    label="Your Street"
                                    name="yourStreet"
                                    value={formData.yourStreet}
                                    onChange={handleChange}
                                    fullWidth
                                    className="form-control mb-3"
                                />
                            </div>
                        </div>
                        <div className="row mb-3">
                            <div className="col-md-6">
                                <TextField
                                    label="Other driver's direction"
                                    name="otherDriverDirection"
                                    value={formData.otherDriverDirection}
                                    onChange={handleChange}
                                    fullWidth
                                    className="form-control mb-3"
                                />
                            </div>
                            <div className="col-md-6">
                                <TextField
                                    label="Other Driver Street"
                                    name="otherDriverStreet"
                                    value={formData.otherDriverStreet}
                                    onChange={handleChange}
                                    fullWidth
                                    className="form-control mb-3"
                                />
                            </div>
                        </div>
                        <div className="row mb-3">
                            <div className="col-md-6">
                                <TextField
                                    label="Did you have a stop sign or a traffic light"
                                    name="yourSignOrSignal"
                                    value={formData.yourSignOrSignal}
                                    onChange={handleChange}
                                    fullWidth
                                    className="form-control mb-3"
                                />
                            </div>
                            <div className="col-md-6">
                                <TextField
                                    label="Other driver have a stop sign or a traffic light"
                                    name="otherDriverSignOrSignal"
                                    value={formData.otherDriverSignOrSignal}
                                    onChange={handleChange}
                                    fullWidth
                                    className="form-control mb-3"
                                />
                            </div>
                        </div>
                        <div className="row mb-3">
                            <div className="col-md-6">
                                <TextField
                                    label="Did you fail to observe sign or signal"
                                    name="failedToObserve"
                                    value={formData.failedToObserve}
                                    onChange={handleChange}
                                    fullWidth
                                    className="form-control mb-3"
                                />
                            </div>
                            <div className="col-md-6">
                                <TextField
                                    label="Did other Driver fail to observe sign or signal"
                                    name="otherDriverFailedToObserve"
                                    value={formData.otherDriverFailedToObserve}
                                    onChange={handleChange}
                                    fullWidth
                                    className="form-control mb-3"
                                />
                            </div>
                        </div>
                        <div className="row mb-3">
                            <div className="col-12">
                                <TextField
                                    label="Which vehicle entered the intersection first?"
                                    name="firstVehicle"
                                    value={formData.firstVehicle}
                                    onChange={handleChange}
                                    fullWidth
                                    className="form-control mb-3"
                                />
                            </div>
                        </div>
                        <div className="row mb-3">
                            <div className="col-md-6">
                                <TextField
                                    label="What signals did you give"
                                    name="yourSignals"
                                    value={formData.yourSignals}
                                    onChange={handleChange}
                                    fullWidth
                                    className="form-control mb-3"
                                />
                            </div>
                            <div className="col-md-6">
                                <TextField
                                    label="What signals did other driver give"
                                    name="otherDriverSignals"
                                    value={formData.otherDriverSignals}
                                    onChange={handleChange}
                                    fullWidth
                                    className="form-control mb-3"
                                />
                            </div>
                        </div>
                        <div className="row mb-3">
                            <div className="col-md-6">
                                <TextField
                                    label="Were you drinking alcohol beer wine liquor etc."
                                    name="youDrinking"
                                    value={formData.youDrinking}
                                    onChange={handleChange}
                                    fullWidth
                                    className="form-control mb-3"
                                />
                            </div>
                            <div className="col-md-6">
                                <TextField
                                    label="Was other driver drinking alcohol beer wine liquor etc."
                                    name="otherDriverDrinking"
                                    value={formData.otherDriverDrinking}
                                    onChange={handleChange}
                                    fullWidth
                                    className="form-control mb-3"
                                />
                            </div>
                        </div>
                        <div className="row mb-3">
                            <div className="col-md-6">
                                <TextField
                                    label="Were you ticketed for any Violation"
                                    name="ticketed"
                                    value={formData.ticketed}
                                    onChange={handleChange}
                                    fullWidth
                                    className="form-control mb-3"
                                />
                            </div>
                            <div className="col-md-6">
                                <TextField
                                    label="What Violation"
                                    name="violation"
                                    value={formData.violation}
                                    onChange={handleChange}
                                    fullWidth
                                    className="form-control mb-3"
                                />
                            </div>
                        </div>
                        <div className="row mb-3">
                            <div className="col-md-6">
                                <TextField
                                    label="Did you admit blame for the accident"
                                    name="admitBlame"
                                    value={formData.admitBlame}
                                    onChange={handleChange}
                                    fullWidth
                                    className="form-control mb-3"
                                />
                            </div>
                            <div className="col-md-6">
                                <TextField
                                    label="Did Other driver admit blame for the accident"
                                    name="otherDriverAdmitBlame"
                                    value={formData.otherDriverAdmitBlame}
                                    onChange={handleChange}
                                    fullWidth
                                    className="form-control mb-3"
                                />
                            </div>
                        </div>
                        <div className="row mb-3">
                            <div className="col-md-4">
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={formData.reportedToPolice}
                                            onChange={handleCheckboxChange}
                                            name="reportedToPolice"
                                        />
                                    }
                                    label="Is accident reported to police"
                                />
                            </div>
                            <div className="col-md-4">
                                <TextField
                                    label="Which police dept (city or town)"
                                    name="policeDept"
                                    value={formData.policeDept}
                                    onChange={handleChange}
                                    fullWidth
                                    className="form-control mb-3"
                                />
                            </div>
                            <div className="col-md-4">
                                <TextField
                                    label="Police Report #"
                                    name="policeReportNumber"
                                    value={formData.policeReportNumber}
                                    onChange={handleChange}
                                    fullWidth
                                    className="form-control mb-3"
                                />
                            </div>
                        </div>
                    </div>
                )
            case 3:
                return (
                    <div className="form-step Passnger_add_injury_add_table ">
                        <h3 className="stepHead">List all Witnesses, who were not passengers</h3>
                        <TableContainer component={Paper} className="mb-4">
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Name</TableCell>
                                        <TableCell>Age</TableCell>
                                        <TableCell>Address</TableCell>
                                        <TableCell>Phone</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {formData.witnesses.map((witness, index) => (
                                        <TableRow key={index}>
                                            <TableCell>
                                                <TextField
                                                    name={`witness-name-${index}`}
                                                    value={witness.name}
                                                    onChange={(e) => handleWitnessChange(index, e)}
                                                    fullWidth
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <TextField
                                                    name={`witness-age-${index}`}
                                                    value={witness.age}
                                                    onChange={(e) => handleWitnessChangeAge(index, e)}
                                                    fullWidth
                                                    onInput={handleNumbersChange}
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <TextField
                                                    name={`witness-address-${index}`}
                                                    value={witness.address}
                                                    onChange={(e) => handleWitnessChange(index, e)}
                                                    fullWidth
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <TextField
                                                    name={`witness-phone-${index}`}
                                                    value={witness.phone}
                                                    onChange={(e) => handleWitnessChange(index, e)}
                                                    fullWidth
                                                />
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <Button variant="outlined" onClick={addWitness} className="mb-4">
                            Add Witness
                        </Button>

                        <div className="row mb-3">
                            <div className="col-md-6 wasAnyInjuredField">
                                <FormControl component="fieldset" className="mb-3">
                                    <InputLabel>Was any one injured</InputLabel>
                                    <Select
                                        name="anyoneInjured"
                                        value={formData.anyoneInjured}
                                        onChange={handleChange}
                                        label="Was any one injured"
                                    >
                                        <MenuItem value="yes">Yes</MenuItem>
                                        <MenuItem value="no">No</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                        </div>

                        <h3 className="stepHead">Body injury</h3>
                        <TableContainer component={Paper} className="mb-4">
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Name</TableCell>
                                        <TableCell>Age</TableCell>
                                        <TableCell>Sex (M/F)</TableCell>
                                        <TableCell>Address</TableCell>
                                        <TableCell>Injury Type</TableCell>
                                        <TableCell>Which vehicle</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {formData.injuries.map((injury, index) => (
                                        <TableRow key={index}>
                                            <TableCell>
                                                <TextField
                                                    name={`injury-name-${index}`}
                                                    value={injury.name}
                                                    onChange={(e) => handleInjuryChange(index, e)}
                                                    fullWidth
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <TextField
                                                    name={`injury-age-${index}`}
                                                    value={injury.age}
                                                    onChange={(e) => handleInjuryChangeAge(index, e)}
                                                    fullWidth
                                                    onInput={handleNumbersChange}
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <Select
                                                    name={`injury-sex-${index}`}
                                                    value={injury.sex}
                                                    onChange={(e) => handleInjuryChange(index, e)}
                                                    fullWidth
                                                    className="injujry_select"
                                                >
                                                    <MenuItem value="M">M</MenuItem>
                                                    <MenuItem value="F">F</MenuItem>
                                                </Select>
                                            </TableCell>
                                            <TableCell>
                                                <TextField
                                                    name={`injury-address-${index}`}
                                                    value={injury.address}
                                                    onChange={(e) => handleInjuryChange(index, e)}
                                                    fullWidth
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <TextField
                                                    name={`injury-injuryType-${index}`}
                                                    value={injury.injuryType}
                                                    onChange={(e) => handleInjuryChange(index, e)}
                                                    fullWidth
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <TextField
                                                    name={`injury-vehicle-${index}`}
                                                    value={injury.vehicle}
                                                    onChange={(e) => handleInjuryChange(index, e)}
                                                    fullWidth
                                                />
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <Button variant="outlined" onClick={addInjury} className="mb-4">
                            Add Injury
                        </Button>

                        <div className="row mb-3">
                            <div className="col-md-4">
                                <TextField
                                    label="Where Taken"
                                    name="whereTaken"
                                    value={formData.whereTaken}
                                    onChange={handleChange}
                                    fullWidth
                                    className="form-control mb-3"
                                />
                            </div>
                            <div className="col-md-4">
                                <TextField
                                    label="Doctor"
                                    name="doctor"
                                    value={formData.doctor}
                                    onChange={handleChange}
                                    fullWidth
                                    className="form-control mb-3"
                                />
                            </div>
                            <div className="col-md-4">
                                <TextField
                                    label="Hospital/doctor Address"
                                    name="hospitalAddress"
                                    value={formData.hospitalAddress}
                                    onChange={handleChange}
                                    fullWidth
                                    className="form-control mb-3"
                                />
                            </div>
                        </div>

                        <div className="row mb-3">
                            <div className="col-12">
                                <TextField
                                    label="IMPORTANT: Describe in your own words how the accident happened"
                                    name="accidentDescription"
                                    value={formData.accidentDescription}
                                    onChange={handleChange}
                                    fullWidth
                                    multiline
                                    rows={5}
                                    className="form-control mb-3"
                                />
                            </div>
                        </div>

                        <div className="row mb-3">
                            <h3 className="stepHead2">Signature <span style={{ color: "red" }}>*</span></h3>
                            <div className="signature-section col-md-12 mb-3">
                                <SignatureCanvas
                                    ref={signaturePad}
                                    penColor="black"
                                    canvasProps={{
                                        height: 200,
                                        width: 300,
                                        className: "signature-canvas border rounded",
                                    }}
                                />

                                {signatureError && (
                                    <div style={{ color: "red", marginTop: "5px" }}>
                                        Please fill your signature
                                    </div>
                                )}
                                <div className="col-5 mb-3">
                                    <Button
                                        variant="outlined"
                                        color="error"
                                        size="small"
                                        onClick={() => {
                                            if (signaturePad.current) {
                                                signaturePad.current.clear();
                                                setSignatureData("");
                                                setSignatureError(false);
                                            }
                                        }}
                                        style={{ marginTop: "10px", textTransform: "none" }}
                                    >
                                        Clear Signature
                                    </Button>
                                </div>


                            </div>
                            {/**----------date ---------- */}

                            <div className="col-md-4  date_accidnet_pick" >
                                <LocalizationProvider dateAdapter={AdapterDayjs} onClick={handleDatePickerOpen2}>
                                    <DatePicker
                                        label="Date"
                                        value={selectedDate ? dayjs(selectedDate, "DD/MM/YYYY") : null}
                                        onChange={handleDateChange2}
                                        format="DD/MM/YYYY"
                                        open={openDatePickerAccident2}
                                        onClose={handleDatePickerClose2}
                                        onOpen={handleDatePickerOpen2}
                                        slotProps={{
                                            textField: {
                                                fullWidth: true,
                                                placeholder: "DD/MM/YYYY",
                                                className: "form-control mb-3",
                                                onClick: handleDatePickerOpen2
                                            }
                                        }}
                                    />
                                </LocalizationProvider>
                            </div>

                        </div>
                    </div>
                )
            case 4:
                return (
                    <div className="form-step">


                        <div className="row mb-4">
                            {/* Your Car Images */}
                            <div className="col-md-4">
                                <h4 className="stepHead2">Upload pictures of damage to your car</h4>
                                <div
                                    className="border border-dashed rounded p-4 text-center mb-3"
                                    onDragOver={handleDragOver}
                                    onDrop={(e) => handleDrop(e, "yourCarImages")}
                                    onClick={() => document.getElementById("yourCarImagesInput").click()}
                                >
                                    <div className="d-flex flex-column align-items-center">
                                        <div className="upload-icon mb-2" style={{ fontSize: '2rem' }}>↑</div>
                                        <h5 className="browswe_file_head">Browse Files</h5>
                                        <p className="text-muted">Drag and drop files here</p>

                                        <input
                                            id="yourCarImagesInput"
                                            type="file"
                                            hidden
                                            multiple
                                            accept="image/*"
                                            onChange={(e) => handleFileChange(e, "yourCarImages")}
                                        />
                                    </div>
                                </div>
                                {formData.yourCarImages.length > 0 && (
                                    <div className="d-flex flex-wrap gap-2 mb-3">
                                        {formData.yourCarImages.map((file, index) => (
                                            <div key={index} className="position-relative border rounded p-1">
                                                <img
                                                    src={URL.createObjectURL(file) || "/placeholder.svg"}
                                                    alt={`Your car damage ${index + 1}`}
                                                    style={{ width: "100px", height: "100px", objectFit: "cover" }}
                                                />
                                                <Button
                                                    size="small"
                                                    color="error"
                                                    onClick={() => removeFile("yourCarImages", index)}
                                                    style={{ position: "absolute", top: "0", right: "0", padding: "0", minWidth: "24px" }}
                                                >
                                                    ×
                                                </Button>
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>
                            {/* Other Car Images */}
                            <div className="col-md-4">
                                <h4 className="stepHead2">Upload pictures of damage of other car</h4>
                                <div
                                    className="border border-dashed rounded p-4 text-center mb-3"
                                    onDragOver={handleDragOver}
                                    onDrop={(e) => handleDrop(e, "otherCarImages")}
                                    onClick={() => document.getElementById("otherDamageCarInput").click()}
                                >
                                    <div className="d-flex flex-column align-items-center">
                                        <div className="upload-icon mb-2" style={{ fontSize: '2rem' }}>↑</div>
                                        <h5 className="browswe_file_head">Browse Files</h5>
                                        <p className="text-muted">Drag and drop files here</p>


                                        <input
                                            id="otherDamageCarInput"
                                            type="file"
                                            hidden
                                            multiple
                                            accept="image/*"
                                            onChange={(e) => handleFileChange(e, "otherCarImages")}
                                        />
                                    </div>
                                </div>
                                {formData.otherCarImages.length > 0 && (
                                    <div className="d-flex flex-wrap gap-2 mb-3">
                                        {formData.otherCarImages.map((file, index) => (
                                            <div key={index} className="position-relative border rounded p-1">
                                                <img
                                                    src={URL.createObjectURL(file) || "/placeholder.svg"}
                                                    alt={`Other car damage ${index + 1}`}
                                                    style={{ width: "100px", height: "100px", objectFit: "cover" }}
                                                />
                                                <Button
                                                    size="small"
                                                    color="error"
                                                    onClick={() => removeFile("otherCarImages", index)}
                                                    style={{ position: "absolute", top: "0", right: "0", padding: "0", minWidth: "24px" }}
                                                >
                                                    ×
                                                </Button>
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>
                            {/* Police Report Images */}
                            <div className="col-md-4">
                                <h4 className="stepHead2">Upload pictures of Police report</h4>
                                <div
                                    className="border border-dashed rounded p-4 text-center mb-3"
                                    onDragOver={handleDragOver}
                                    onDrop={(e) => handleDrop(e, "policeReportImages")}
                                    onClick={() => document.getElementById("policeReportImagesInput").click()}
                                >
                                    <div className="d-flex flex-column align-items-center">
                                        <div className="upload-icon mb-2" style={{ fontSize: '2rem' }}>↑</div>
                                        <h5 className="browswe_file_head" >Browse Files</h5>
                                        <p className="text-muted">Drag and drop files here</p>

                                        <input
                                            id="policeReportImagesInput"
                                            type="file"
                                            hidden
                                            multiple
                                            accept="image/*"
                                            onChange={(e) => handleFileChange(e, "policeReportImages")}
                                        />

                                    </div>
                                </div>
                                {formData.policeReportImages.length > 0 && (
                                    <div className="d-flex flex-wrap gap-2 mb-3">
                                        {formData.policeReportImages.map((file, index) => (
                                            <div key={index} className="position-relative border rounded p-1">
                                                <img
                                                    src={URL.createObjectURL(file) || "/placeholder.svg"}
                                                    alt={`Police report ${index + 1}`}
                                                    style={{ width: "100px", height: "100px", objectFit: "cover" }}
                                                />
                                                <Button
                                                    size="small"
                                                    color="error"
                                                    onClick={() => removeFile("policeReportImages", index)}
                                                    style={{ position: "absolute", top: "0", right: "0", padding: "0", minWidth: "24px" }}
                                                >
                                                    ×
                                                </Button>
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>
                        </div>

                        {/* Additional Notes */}
                        <div className="row mb-4">
                            <div className="col-12 aditional_note">
                                <h4 className="stepHead">Additional notes (if any)</h4>
                                <TextField

                                    name="additionalNotes"
                                    value={formData.additionalNotes}
                                    onChange={handleChange}
                                    fullWidth
                                    multiline
                                    rows={4}
                                    className="form-control  mb-3"
                                    placeholder="Add any additional information about the uploaded images here..."
                                />
                            </div>
                        </div>
                    </div>
                )
            default:
                return "Unknown step"
        }
    }

    return (
        <Layout>
            <div className="container-lg py-4">
                <Paper elevation={3} className=" p-4">
                    <Stepper activeStep={activeStep} alternativeLabel className="step_head_top mb-4">
                        {steps.map((label, index) => (
                            <Step key={label} ref={(el) => (stepRefs.current[index] = el)}>
                                <StepLabel className="topTabHeadingsAcc">{label}</StepLabel>
                            </Step>
                        ))}
                    </Stepper>


                    <div>
                        {getStepContent(activeStep)}
                        <div className="Accnt_steps_buttons">
                            {activeStep > 0 && (
                                <Button className="back_bttn_accnForm" variant="contained" color="secondary" disabled={activeStep === 0} onClick={handleBack}>
                                    Back
                                </Button>
                            )}
                            <Button
                                className="Next_bttn_accnForm"
                                variant="contained"
                                onClick={activeStep === steps.length - 1 ? () => alert("Form submitted!") : handleNext}
                            >
                                {activeStep === steps.length - 1 ? "Submit" : "Next"}
                            </Button>
                        </div>
                    </div>
                </Paper>
            </div>
        </Layout>
    )
}
