import React from 'react'
import "../CustomCSS/PrimaryPolicyNew.css"
import privacyPolicy from "../../Assests/imgs/page-header/privacyPolicy.jpg";
import Layout from "../Layout/Layout";
export default function PrimaryPolicyNew() {
    return (
        <Layout headerStyle={2} footerStyle={1}>
            <div className="custom-container position-relative mx-auto">
                <div className="bg-overlay rounded-12 overflow-hidden">
                    <img
                        className="w-100 h-100 img-fluid img-banner"
                        src={privacyPolicy}
                        alt="Carento"
                    />
                </div>
                <div className="container position-absolute z-1 top-50 start-50 pb-70 translate-middle text-center">
                    <h2 className="text-white mt-4">Privacy & Policy</h2>

                </div>
            </div>


            {/**----------------------Privary Policy Content------------- */}

            <div className='privary_policy_Container'>
                <div className="container-lg my-3 privacy-policy-container">
                    <div className="row justify-content-center">

                        <div className="policy-header text-center mb-4">
                            <p className="text-muted">Effective Date: 01/04/2025</p>
                            <p className="mb-0">
                                <strong>Company Name:</strong> Rapid Car Leasing (RCL-USA)
                            </p>
                            <p>
                                <strong>Website:</strong>{" "}
                                <a href="https://www.rapidcarleasing.com/" className="text-decoration-none">
                                    https://www.rapidcarleasing.com/
                                </a>
                            </p>
                        </div>

                        <div className="policy-intro mb-4">
                            <p>
                                Rapid Car Leasing ("we," "our," or "us") respects your privacy and is committed to protecting the
                                personal information you share with us. This Privacy Policy outlines how we collect, use, disclose, and
                                safeguard your information when you visit our website or use our services.
                            </p>
                        </div>

                        <div className="policy-section mb-4">
                            <h4 className="fw-bold">1. Information We Collect</h4>

                            <h5 className="mt-3">a. Information You Provide:</h5>
                            <ul className="list-unstyled ps-3">
                                <li>- Full Name</li>
                                <li>- Email Address</li>
                                <li>- Phone Number</li>
                                <li>- Driver's License Details</li>
                                <li>- MVR (Motor Vehicle Report) Uploads</li>
                                <li>- Insurance Documents</li>
                                <li>- Payment Information (through third-party services like Stripe)</li>
                                <li>- Contact Details of Emergency Contacts (if provided)</li>
                            </ul>

                            <h5 className="mt-3">b. Automatically Collected Information:</h5>
                            <ul className="list-unstyled ps-3">
                                <li>- IP address</li>
                                <li>- Browser type</li>
                                <li>- Device information</li>
                                <li>- Pages visited and duration</li>
                                <li>- Cookies and usage data</li>
                            </ul>
                        </div>

                        <div className="policy-section mb-4">
                            <h4 className="fw-bold">2. How We Use Your Information</h4>
                            <p>We use your information to:</p>
                            <ul className="list-unstyled ps-3">
                                <li>- Process vehicle lease applications</li>
                                <li>- Verify identity and documentation</li>
                                <li>- Provide and manage services</li>
                                <li>- Communicate with you about your lease or application</li>
                                <li>- Send payment reminders and confirmations</li>
                                <li>- Improve our website and customer experience</li>
                                <li>- Comply with legal obligations</li>
                            </ul>
                        </div>

                        <div className="policy-section mb-4">
                            <h4 className="fw-bold">3. Sharing of Information</h4>
                            <p>We may share your information with:</p>
                            <ul className="list-unstyled ps-3">
                                <li>- Insurance partners (for verification and approvals)</li>
                                <li>- Payment processors (Stripe, Zelle)</li>
                                <li>- Cloud hosting services (e.g., AWS)</li>
                                <li>- Government or law enforcement when legally required</li>
                            </ul>
                            <p>We do not sell your personal information to third parties.</p>
                        </div>

                        <div className="policy-section mb-4">
                            <h4 className="fw-bold">4. Cookies and Tracking Technologies</h4>
                            <p>
                                We use cookies and tracking tools to improve site performance and provide a better user experience. You
                                may disable cookies in your browser settings.
                            </p>
                        </div>

                        <div className="policy-section mb-4">
                            <h4 className="fw-bold">5. Data Security</h4>
                            <p>
                                We implement industry-standard encryption and security measures to protect your personal information.
                                However, no electronic transmission is 100% secure.
                            </p>
                        </div>

                        <div className="policy-section mb-4">
                            <h4 className="fw-bold">6. Your Choices</h4>
                            <p>You have the right to:</p>
                            <ul className="list-unstyled ps-3">
                                <li>- Access, update, or delete your personal data</li>
                                <li>- Opt-out of marketing communications</li>
                                <li>- Request information about data usage under CCPA (if you are a California resident)</li>
                            </ul>
                        </div>

                        <div className="policy-section mb-4">
                            <h4 className="fw-bold">7. Third-Party Links</h4>
                            <p>
                                Our website may contain links to third-party websites. We are not responsible for their privacy
                                practices. Please review their policies separately.
                            </p>
                        </div>

                        <div className="policy-section mb-4">
                            <h4 className="fw-bold">8. Children's Privacy</h4>
                            <p>
                                Our services are not intended for individuals under the age of 18. We do not knowingly collect
                                information from minors.
                            </p>
                        </div>

                        <div className="policy-section mb-4">
                            <h4 className="fw-bold">9. Changes to This Policy</h4>
                            <p>
                                We may update this Privacy Policy periodically. Changes will be posted on this page with a revised
                                effective date.
                            </p>
                        </div>

                        <div className="policy-section mb-4">
                            <h4 className="fw-bold">10. Contact Us</h4>
                            <p>
                                If you have any questions about this Privacy Policy or how your data is handled, please contact us at:
                            </p>
                            <div className="contact-info mt-3">
                                <p className="mb-1">support@rapidcarleasing.com</p>
                                <p className="mb-1">+1 7083123331</p>
                                <p>1400 Renaissance Dr UNIT 201, Park Ridge, IL 60068, United States</p>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </Layout>
    )
}
