import React from "react";
import brand1 from "../../Assests/imgs/cta/cta-4/instacart.png";
import brand2 from "../../Assests/imgs/cta/cta-4/goPuff.png";
import brand3 from "../../Assests/imgs/cta/cta-4/doordash.png";
import brand4 from "../../Assests/imgs/cta/cta-4/amazonflex.png";
import brand5 from "../../Assests/imgs/cta/cta-4/uber.png";
import brand6 from "../../Assests/imgs/cta/cta-4/lyft.png";

const logos = [brand1, brand2, brand3, brand4, brand5, brand6];

const AboutBenefits = () => {
  const fullLoop = [...logos, ...logos]; // For infinite loop

  return (
    <section className="section-cta-4 position-relative overflow-hidden">
      <div className="container position-relative z-1">
        <div className="text-center">
          <h4 className="mt-4">Get on the Road with a Rideshare-Ready Car</h4>
          <span
            className="text-sm p-3 rounded-12"
            style={{ color: "#737373", fontSize: "20px" }}
          >
            Affordable Rentals Starting at $55/Day or $350/Week
          </span>
        </div>

        <div className="brandLogos-wrapper">
          <div className="brandLogos-track">
            {fullLoop.map((logo, i) => (
              <div className="brandLogos-item" key={i}>
                <img className="brandstylesClas" src={logo} alt={`logo-${i}`} />
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutBenefits;
