import React, { useState } from "react";
import Layout from "../Layout/Layout";
import { Link } from "react-router-dom";
import Termplc from "../../Assests/imgs/page-header/Termplc.jpg";

const TermsOfUseNew = () => {
    return (
        <Layout headerStyle={2} footerStyle={1}>
            <div>
                <div className="page-header-2 pt-30 background-body">
                    <div className="custom-container position-relative mx-auto">
                        <div className="bg-overlay rounded-12 overflow-hidden">
                            <img
                                className="w-100 h-100 img-fluid img-banner"
                                src={Termplc}
                                alt="Carento"
                            />
                        </div>
                        <div className="container position-absolute z-1 top-50 start-50 pb-70 translate-middle text-center">
                            <h2 className="text-white mt-4">Terms And Services</h2>
                            {/* <span className="text-white text-lg-medium">
                Search and find your best car rental with an easy way
              </span> */}
                        </div>
                    </div>

                    {/**----------------------Privary Policy Content------------- */}

                    <div className='privary_policy_Container'>
                        <div className="container-lg my-3 privacy-policy-container">
                            <div className="row justify-content-center">


                                <div className="policy-section mb-4">
                                    <h4 className="fw-bold">1. Eligibility</h4>

                                    <h5 className="mt-3">To rent a vehicle from RCL-USA, you must:
                                    </h5>
                                    <ul className="list-unstyled ps-3">
                                        <li>-  Be at least 21 years of age.</li>
                                        <li>-  Hold a valid U.S. driver’s license.</li>
                                        <li>- Provide proof of insurance approval (via MVR & license verification).</li>
                                        <li>- Agree to RCL’s terms, privacy policy, and driver agreement before use.</li>

                                    </ul>


                                </div>

                                <div className="policy-section mb-4">
                                    <h4 className="fw-bold">2. Rental Use Policy</h4>
                                    <p>RCL vehicles are rented exclusively for gig and rideshare use (e.g., Uber, Lyft, Instacart, Doordash, etc.). They may not
                                        be used for:
                                        :</p>
                                    <ul className="list-unstyled ps-3">
                                        <li>- Personal leisure or tourism</li>
                                        <li>- Towing or transporting illegal goods</li>
                                        <li>-  Racing or reckless driving
                                        </li>

                                    </ul>
                                </div>

                                <div className="policy-section mb-4">
                                    <h4 className="fw-bold">3. Payment Terms</h4>

                                    <ul className="list-unstyled ps-3">
                                        <li>- Rentals start at $350/week or $50/day, billed weekly unless otherwise agreed.</li>
                                        <li>- Payment is collected in advance via Stripe or approved third-party platforms.</li>
                                        <li>- A $250 refundable security deposit is required upon insurance approval.</li>
                                        <li>-  Late payments may result in suspension or repossession of the vehicle.</li>
                                    </ul>

                                </div>

                                <div className="policy-section mb-4">
                                    <h4 className="fw-bold">4. Insurance & Documentation</h4>
                                    <ul className="list-unstyled ps-3">
                                        <li>-Drivers must submit.</li>
                                        <li>- A valid Driver’s License.</li>
                                        <li>-A recent MVR (Motor Vehicle Record).</li>
                                        <li>- Insurance is included in the rental package upon verification.</li>
                                        <li>- The application cannot proceed without proper documentation.</li>
                                    </ul>
                                </div>

                                <div className="policy-section mb-4">
                                    <h4 className="fw-bold">5. Maintenance & Support
                                    </h4>
                                    <ul className="list-unstyled ps-3">
                                        <li>-All leases come with unlimited mileageDrivers must submit.</li>
                                        <li>-There are no app restrictions? use your car with any approved gig platform.</li>
                                        <li>-Vehicles must remain within the state unless otherwise permitted in writing.</li>

                                    </ul>

                                </div>

                                <div className="policy-section mb-4">
                                    <h4 className="fw-bold">6. Vehicle Return Policy</h4>

                                    <ul className="list-unstyled ps-3">
                                        <li>-Return the vehicle in the same condition as received (excluding standard wear and tear).</li>
                                        <li>- A return inspection will be conducted upon drop-off.</li>
                                        <li>- Fees apply for damage, excessive dirt, missing items, or late returns.</li>
                                    </ul>
                                </div>

                                <div className="policy-section mb-4">
                                    <h4 className="fw-bold">7. Cancellation & Refunds
                                    </h4>
                                    <ul className="list-unstyled ps-3">
                                        <li>-Cancellations must be made 24 hours prior to the next billing cycle.</li>
                                        <li>-Early returns will not result in pro-rated refunds unless agreed in writing.</li>

                                    </ul>
                                </div>

                                <div className="policy-section mb-4">
                                    <h4 className="fw-bold">8. Termination</h4>
                                    <p>
                                        RCL reserves the right to terminate any lease if:

                                    </p>
                                    <ul className="list-unstyled ps-3">
                                        <li>-Payments are not made on time.</li>
                                        <li>-The vehicle is misused or used in violation of these terms.
                                        </li>
                                        <li>-Fraudulent or false information is provided during application.
                                        </li>

                                    </ul>
                                </div>

                                <div className="policy-section mb-4">
                                    <h4 className="fw-bold">9. Liability & Indemnity</h4>
                                    <ul className="list-unstyled ps-3">
                                        <li>-RCL is not liable for personal items left in the vehicle.</li>
                                        <li>-The driver assumes full responsibility for tickets, tolls, and any legal violations.
                                        </li>
                                        <li>-The driver agrees to indemnify RCL against all claims arising from misuse.
                                        </li>

                                    </ul>
                                </div>

                                <div className="policy-section mb-4">
                                    <h4 className="fw-bold">Changes to Terms</h4>
                                    <p>
                                        RCL reserves the right to modify these Terms & Conditions at any time. Updates will be posted on this page and take
                                        effect immediately.

                                    </p>

                                </div>
                                <div className="policy-section mb-4">
                                    <h4 className="fw-bold">Contact Information</h4>
                                    <p>
                                        If you have questions or need support, please contact:

                                    </p>
                                    <div className="contact-info mt-3">


                                        <p className="mb-1"><span>Email: </span> support@rapidcarleasing.com</p>
                                        <p className="mb-1"><span>Phone: </span> +1 7083123331</p>
                                        <p><span>Address: </span> 1400 Renaissance Dr UNIT 201, Park Ridge, IL 60068, United States</p>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default TermsOfUseNew;
