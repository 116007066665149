import { useState, useEffect, useRef } from "react";
import "react-datepicker/dist/react-datepicker.css";
import "../../Assests/css/aplicationform.css";
import Banner from "../../Assests/imgs/page-header/banner.png";
import Layout from "../Layout/Layout";
import DatePicker from "react-datepicker";
import SignatureCanvas from "react-signature-canvas";
import { useLocation, useNavigate } from "react-router-dom";
import { Modal, Button, Form } from "react-bootstrap";
import {
  server_post_data,
  save_booking,
  save_image_pdf,
} from "../../ServiceConnection/serviceconnection";
import {
  handleAlphabetsNumberWithoutSpaceChange,
  handleError,
  handleNumbersChange,
} from "../../CommonJquery/CommonJquery";
import { retrieveData, storeData } from "../../LocalConnection/LocalConnection";
import MVRPopup from "../Elements/MVRPopup";
const AplicationForm = () => {
  // Add this array at the top of your component, after the useState declarations
  const usStates = [
    { name: "Alabama", abbr: "AL" },
    { name: "Alaska", abbr: "AK" },
    { name: "Arizona", abbr: "AZ" },
    { name: "Arkansas", abbr: "AR" },
    { name: "California", abbr: "CA" },
    { name: "Colorado", abbr: "CO" },
    { name: "Connecticut", abbr: "CT" },
    { name: "Delaware", abbr: "DE" },
    { name: "Florida", abbr: "FL" },
    { name: "Georgia", abbr: "GA" },
    { name: "Hawaii", abbr: "HI" },
    { name: "Idaho", abbr: "ID" },
    { name: "Illinois", abbr: "IL" },
    { name: "Indiana", abbr: "IN" },
    { name: "Iowa", abbr: "IA" },
    { name: "Kansas", abbr: "KS" },
    { name: "Kentucky", abbr: "KY" },
    { name: "Louisiana", abbr: "LA" },
    { name: "Maine", abbr: "ME" },
    { name: "Maryland", abbr: "MD" },
    { name: "Massachusetts", abbr: "MA" },
    { name: "Michigan", abbr: "MI" },
    { name: "Minnesota", abbr: "MN" },
    { name: "Mississippi", abbr: "MS" },
    { name: "Missouri", abbr: "MO" },
    { name: "Montana", abbr: "MT" },
    { name: "Nebraska", abbr: "NE" },
    { name: "Nevada", abbr: "NV" },
    { name: "New Hampshire", abbr: "NH" },
    { name: "New Jersey", abbr: "NJ" },
    { name: "New Mexico", abbr: "NM" },
    { name: "New York", abbr: "NY" },
    { name: "North Carolina", abbr: "NC" },
    { name: "North Dakota", abbr: "ND" },
    { name: "Ohio", abbr: "OH" },
    { name: "Oklahoma", abbr: "OK" },
    { name: "Oregon", abbr: "OR" },
    { name: "Pennsylvania", abbr: "PA" },
    { name: "Rhode Island", abbr: "RI" },
    { name: "South Carolina", abbr: "SC" },
    { name: "South Dakota", abbr: "SD" },
    { name: "Tennessee", abbr: "TN" },
    { name: "Texas", abbr: "TX" },
    { name: "Utah", abbr: "UT" },
    { name: "Vermont", abbr: "VT" },
    { name: "Virginia", abbr: "VA" },
    { name: "Washington", abbr: "WA" },
    { name: "West Virginia", abbr: "WV" },
    { name: "Wisconsin", abbr: "WI" },
    { name: "Wyoming", abbr: "WY" },
    { name: "District of Columbia", abbr: "DC" },
  ];
  const location = useLocation();
  const navigate = useNavigate();
  // const data = location.state;
  const [step, setStep] = useState(1);
  const maxDob = new Date();
  const [agreed, setAgreed] = useState(false);
  const [numberOfDays, setNumberOfDays] = useState(null);
  const [totalCost, setTotalCost] = useState(null);
  maxDob.setFullYear(maxDob.getFullYear() - 16);
  const customer_id = retrieveData("customer_id");
  const customer_name = retrieveData("customer_name");
  const customer_email = retrieveData("customer_email");
  const data = JSON.parse(retrieveData("current_data"));
  const [showPopup, setShowPopup] = useState(true);
  const [drivingState, setdrivingState] = useState(true);
  const dob_date = retrieveData("dob_date");
  const driving_expiration_date = retrieveData("driving_expiration_date");
  // const emergency_contacts = retrieveData("emergency_contacts");
  // const emergency_contacts1 = retrieveData("emergency_contacts1");
  // const emergency_contacts_name = retrieveData("emergency_contacts_name");
  // const emergency_contacts_name1 = retrieveData("emergency_contacts_name1");
  // const emergency_contacts_relation = retrieveData(
  //   "emergency_contacts_relation"
  // );
  // const emergency_contacts_relation1 = retrieveData(
  //   "emergency_contacts_relation1"
  // );
  const present_map_address = retrieveData("present_map_address");

  const nameParts = customer_name.trim().split(" ");
  const firstName = nameParts.length > 0 ? nameParts[0] : "";
  const lastName = nameParts.length > 1 ? nameParts.slice(1).join(" ") : "";
  const [formData, setFormData] = useState({
    mvrDownloaded: "yes",
    mvrFile: null,
    driving_licence_image: null,
    feeAgreement: "",
    firstName: firstName,
    lastName: lastName,
    dob: dob_date !== "" ? dob_date : maxDob,
    // licenseState: "",
    drivingState: "",
    licenseExpiry:
      driving_expiration_date !== ""
        ? new Date(driving_expiration_date)
        : new Date(data.endDate),
    phone: "",
    address: present_map_address,
    email: customer_email,
    // ssn: "",
    // license: "",
    rideshareApproval: "no",
    hireExperience: "",
    carPreference: [],
    rentalDuration: "",
    rentalStart: "",
    carUsage: [],
    carUsageOther: "",
    workApproval: [],
    workApprovalOther: "",
    city: "",
    zipcode: "",
    // emergencyContact1: {
    //   name: emergency_contacts_name,
    //   relation: emergency_contacts_relation,
    //   phone: emergency_contacts,
    // },
    // emergencyContact2: {
    //   name: emergency_contacts_name1,
    //   relation: emergency_contacts_relation1,
    //   phone: emergency_contacts1,
    // },
    submissionDate: "",
    signature_image: null,
  });
  console.log("localstoragedata", data.fromDate);
  console.log("usageOther", formData.carUsageOther)


  const handleChange = async (e) => {
    const { name, value, type, checked } = e.target;

    console.log("handlechnage", name, value, type);

    if (name === "phone" || name === "zipcode") {
      // Remove non-digit characters and limit to 10 digits
      const formattedPhone = value.replace(/\D/g, "").slice(0, 10);
      setFormData({ ...formData, [name]: formattedPhone });
    } else if (type === "checkbox") {
      setFormData((prevData) => ({
        ...prevData,
        [name]: checked
          ? [...prevData[name], value]
          : prevData[name].filter((item) => item !== value),
      }));
    } else if (type === "file") {
      const file = e.target.files[0];
      if (file.size < 3 * 1024 * 1024) {
        handlesaveImage(e.target.files[0], name);
      }
      else {
        alert("File size should be less than 3MB")
      }
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handlesaveImage = async (file, name) => {
    if (!file) return "";

    let data = "";
    let fd_from = new FormData();
    fd_from.append("image", file);

    try {
      const response = await server_post_data(save_image_pdf, fd_from);
      if (response.error) {
        handleError(response.data.message);
      } else {
        data = response.message.image;
        console.log("Image uploaded successfully:", name, data);
        setFormData({ ...formData, [name]: data });
      }
    } catch (error) {
      console.error("Error uploading file:", error);
    }

    return data;
  };

  // const handleEmergencyChange = (e, contactNumber) => {
  //   const { name, value } = e.target;
  //   setFormData((prevData) => ({
  //     ...prevData,
  //     [`emergencyContact${contactNumber}`]: {
  //       ...prevData[`emergencyContact${contactNumber}`],
  //       [name]: value,
  //     },
  //   }));
  // };

  useEffect(() => {
    if (!data?.endDate || !data?.fromDate) {
      console.error("Dates are missing.");
      return;
    }

    const endDate = new Date(data.endDate);
    const fromDate = new Date(data.fromDate);

    if (isNaN(endDate) || isNaN(fromDate)) {
      console.error("Invalid date format.");
      return;
    }

    const differenceInTime = endDate - fromDate;
    let daysDifference = Math.ceil(differenceInTime / (1000 * 60 * 60 * 24));

    if (daysDifference === 0) {
      daysDifference = 1;
    }

    if (daysDifference > 0) {
      setNumberOfDays(daysDifference);
      const carPrice = Number(data.car_price);
      const total = daysDifference * carPrice;
      setTotalCost(total);
    } else {
      console.error("End date is earlier than the from date.");
    }
  }, [data.endDate, data.fromDate]);

  // const handleNext = () => setStep(step + 1);
  const handleBack = () => setStep(step - 1);
  const [errors, setErrors] = useState({});

  const step1Errors = () => {
    let errors = {};
    // if (!formData.mvrDownloaded)
    //   errors.mvrDownloaded = "This field is required.";
    if (formData.mvrDownloaded === "yes" && !formData.mvrFile)
      errors.mvrFile = "Please upload the MVR file.";
    if (!formData.firstName) errors.firstName = "First name is required.";
    if (!formData.lastName) errors.lastName = "Last name is required.";
    // if (!formData.dob) errors.dob = "Date of Birth is required.";
    // if (!formData.licenseExpiry)
    //   errors.licenseExpiry = "License Expiry date is required.";
    if (!formData.phone) {
      errors.phone = "Phone number is required.";
    } else {
      // Remove any non-digit characters
      const phoneNumber = formData.phone.replace(/\D/g, "");

      // Check if the phone number is exactly 10 digits
      if (phoneNumber.length !== 10) {
        errors.phone = "Phone number must be 10 digits long.";
      } else {
        // Additional validation for common US phone number formats
        const firstThreeDigits = phoneNumber.substring(0, 3);
        const invalidAreaCodes = ["000", "111", "911"];

        if (invalidAreaCodes.includes(firstThreeDigits)) {
          errors.phone = "Invalid phone number format.";
        }
      }
    }
    if (!formData.email) errors.email = "Email is required.";
    if (!formData.address) errors.address = "Street Address is required.";
    if (!formData.city) errors.city = "City is required.";
    if (!formData.zipcode) errors.zipcode = "Zipcode is required.";
    if (!formData.driving_licence_image)
      errors.driving_licence_image = "Please upload a PDF of your license.";
    if (!formData.drivingState) errors.drivingState = "Please select a state.";
    return errors;
  };
  const validate = () => {
    let newErrors = {};
    // if (!formData.ssn) newErrors.ssn = "SSN is required.";
    // if (!formData.license) newErrors.license = "Driver's license is required.";

    if (!formData.signature_image) {
      newErrors.signatureImage = "This field is required.";
    }

    if (!formData.rideshareApproval) {
      newErrors.rideshareApproval = "This field is required.";
    }

    // Validate hire experience if rideshare approval is "yes"

    // Validate car usage when rideshare approval is "no"
    if (formData.rideshareApproval === "no") {
      if (formData.carUsage.length === 0) {
        newErrors.carUsage = "Please select at least one usage.";
      }

      // Ensure "Other" car usage has a specified value
      if (
        formData.carUsage.includes("Other") &&
        !formData.carUsageOther?.trim()
      ) {
        newErrors.carUsageOther = "Specify other car usage.";
      }
    } else {
      if (formData.workApproval.length === 0) {
        newErrors.workApproval = "Please select at least one approval.";
      }

      // Ensure "Other" work approval has a specified value
      if (
        formData.workApproval.includes("Other") &&
        !formData.workApprovalOther?.trim()
      ) {
        newErrors.workApprovalOther = "Specify other platform.";
      }
      if (!formData.hireExperience) {
        newErrors.hireExperience = "Experience is required.";
      }
    }

    // Validate work approval when rideshare is "yes"

    if (!formData.rentalStart) {
      newErrors.rentalStart = "Please select a rental start option.";
    }
    // Validate Emergency Contact 1
    // if (!formData.emergencyContact1.name)
    //   newErrors.emergencyContact1_name = "Contact 1 Name is required.";
    // if (!formData.emergencyContact1.relation)
    //   newErrors.emergencyContact1_relation = "Contact 1 Relation is required.";
    // if (!formData.emergencyContact1.phone)
    //   newErrors.emergencyContact1_phone = "Contact 1 Phone is required.";

    // Validate Emergency Contact 2
    // if (!formData.emergencyContact2.name)
    //   newErrors.emergencyContact2_name = "Contact 2 Name is required.";
    // if (!formData.emergencyContact2.relation)
    //   newErrors.emergencyContact2_relation = "Contact 2 Relation is required.";
    // if (!formData.emergencyContact2.phone)
    //   newErrors.emergencyContact2_phone = "Contact 2 Phone is required.";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleNext = () => {
    const errors = step1Errors();
    if (Object.keys(errors).length > 0) {
      setErrors(errors); // Assume `errors` is a state variable to store error messages
      return;
    }
    setStep(step + 1);
  };
  const handleCheckboxChange = (e) => {
    setAgreed(e.target.checked);
  };

  const [signatureFile, setSignatureFile] = useState(null);
  const signaturePad = useRef(null);

  const handleClearSignature = () => {
    if (signaturePad.current) {
      signaturePad.current.clear();
    }
  };

  const handleSaveSignature = () => {
    if (!signaturePad.current || signaturePad.current.isEmpty()) {
      alert("Please provide a signature before proceeding.");
      return;
    }

    const signatureData = signaturePad.current.toDataURL("image/png");
    console.log("Signature Data URL:", signatureData); // Logs the Base64 data URL

    const base64String = signatureData.split(",")[1];
    const byteArray = new Uint8Array(
      atob(base64String)
        .split("")
        .map((c) => c.charCodeAt(0))
    );
    const blob = new Blob([byteArray], { type: "image/png" });
    const file = new File([blob], "signature.png", { type: "image/png" });

    setSignatureFile(file);
    console.log("Signature File:", file); // Logs the File object
    setFormData((prevData) => ({
      ...prevData,
      signature_image: file,
    }));

    alert("Signature saved as PNG and downloaded!");
  };

  const handleSubmitImg = async () => {
    if (!validate()) {
      return;
    }
    let fd_from = new FormData();
    fd_from.append("signature_image", formData.signature_image);
    fd_from.append("emergency_contacts_relation1", "");
    fd_from.append("emergency_contacts_name1", "");
    fd_from.append("emergency_contacts1", "");
    fd_from.append("emergency_contacts_relation", "");
    fd_from.append("emergency_contacts_name", "");
    fd_from.append("emergency_contacts", "");

    if (formData.rideshareApproval === "yes") {
      fd_from.append("using_car", "");
    } else {
      if (formData.carUsageOther !== "") {
        fd_from.append(
          "using_car",
          formData.carUsage + ",~@~" + formData.carUsageOther
        );
      } else {
        fd_from.append("using_car", formData.carUsage);
      }
    }

    if (formData.rideshareApproval === "no") {
      fd_from.append("work_for", "");
    } else {
      if (formData.workApprovalOther !== "") {
        fd_from.append(
          "work_for",
          formData.workApproval + ",~@~" + formData.workApprovalOther
        );
      } else {
        fd_from.append("work_for", formData.workApproval);
      }
    }
    fd_from.append("long_need_car", formData.rentalStart);

    fd_from.append("ready_for_rideshare", formData.rideshareApproval);
    if (formData.rideshareApproval === "no") {
      fd_from.append("passenger_for_hire_expe", "0");
    } else {
      fd_from.append("passenger_for_hire_expe", formData.hireExperience);
    }
    fd_from.append("driving_licence", "");
    fd_from.append("last_digit_ssn", "");
    fd_from.append("present_map_address", formData.address);
    fd_from.append("driving_expiration_date", "2025-01-01");
    fd_from.append("mvr_pdf", formData.mvrFile);
    fd_from.append("mvr_status", formData.mvrDownloaded);
    fd_from.append("driving_licence_image", formData.driving_licence_image);
    fd_from.append("end_date", data.NoOfDays);
    fd_from.append("from_date", formatDateTimenew(data.fromDate));
    fd_from.append("car_id", data.car_id);
    fd_from.append("car_price", data.car_price);
    fd_from.append("total_price", totalCost);
    fd_from.append("mobile_no", formData.phone);
    fd_from.append("email", formData.email);
    fd_from.append("dob", "2025-01-01");
    fd_from.append("last_name", formData.lastName);
    fd_from.append("first_name", formData.firstName);
    fd_from.append("state_dropdown", formData.licenseState);
    fd_from.append("driving_state", formData.drivingState);
    fd_from.append("pin_code", formData.zipcode);
    fd_from.append("city", formData.city);

    // fd_from.append("long_need_car", formData.rentalStart);
    await server_post_data(save_booking, fd_from)
      .then((Response) => {
        if (Response.error) {
          handleError(Response.data.message);
        } else {
          // storeData("dob_date", formData.dob);
          // storeData("driving_expiration_date", formData.licenseExpiry);
          // storeData("emergency_contacts", formData.emergencyContact1.phone);
          // storeData("emergency_contacts1", formData.emergencyContact2.phone);
          // storeData("emergency_contacts_name", formData.emergencyContact1.name);
          // storeData(
          //   "emergency_contacts_name1",
          //   formData.emergencyContact2.name
          // );
          // storeData(
          //   "emergency_contacts_relation",
          //   formData.emergencyContact1.relation
          // );
          // storeData(
          //   "emergency_contacts_relation1",
          //   formData.emergencyContact2.relation
          // );
          storeData("present_map_address", formData.address);
          storeData(
            "customer_name",
            formData.firstName + " " + formData.lastName
          );

          navigate("/my_bookings");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  function formatDateTime(date) {
    const d = new Date(date);

    // Get date components
    const year = d.getFullYear();
    const month = String(d.getMonth() + 1).padStart(2, "0"); // Months are 0-based
    const day = String(d.getDate()).padStart(2, "0");

    // Get time components
    const hours = String(d.getHours()).padStart(2, "0");
    const minutes = String(d.getMinutes()).padStart(2, "0");
    const seconds = String(d.getSeconds()).padStart(2, "0");

    // Combine into desired format
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  }

  function formatDateTimenew(date) {
    const d = new Date(date);

    // Get date components
    const year = d.getFullYear();
    const month = String(d.getMonth() + 1).padStart(2, "0"); // Months are 0-based
    const day = String(d.getDate()).padStart(2, "0");

    // Combine into desired format
    return `${year}-${month}-${day}`;
  }

  const [error, setError] = useState("");

  // Handle changes in the emergency contact fields
  const handleEmergencyChange = (e, contactNumber) => {
    const { name, value } = e.target;

    // Update the form data
    setFormData((prevData) => {
      const updatedData = { ...prevData };
      updatedData[`emergencyContact${contactNumber}`][name] = value;

      // Get both emergency contact details
      const contact1 = updatedData.emergencyContact1;
      const contact2 = updatedData.emergencyContact2;

      // Check if any field of contact 1 matches the corresponding field of contact 2
      if (
        contact1.name === contact2.name ||
        contact1.relation === contact2.relation ||
        contact1.phone === contact2.phone
      ) {
        setError(
          "Emergency Contact 1 and Emergency Contact 2 cannot have the same name, relation, or phone number."
        );
      } else {
        setError(""); // Clear the error if they are not the same
      }

      return updatedData;
    });
  };
  const [isCheckboxEnabled, setIsCheckboxEnabled] = useState(false);
  const [agreed2, setAgreed2] = useState(false);
  const termsRef = useRef(null);

  // const handleSubmit = (e) => {
  //   e.preventDefault();

  //   if (validate()) {
  //     setTermConditionModal(true);
  //   }
  // };

  // const confirmSubmit = async () => {
  //   await handleSubmitImg();
  // };

  console.log(formData.driving_licence_image);
  console.log(formData.mvrFile);
  console.log(formData);
  // console.log(formData.carUsage)
  return (
    <Layout>
      <div>
        <MVRPopup show={showPopup} handleClose={() => setShowPopup(false)} />
        <div className="page-header pt-30 background-body">
          <div className="position-relative mx-auto">
            <div className="container">
              <h5 className="text-dark">Application Form</h5>
              <span className="text-dark text-xl-medium">
                Start your car rental booking by completing this quick 2-step
                application!
              </span>
            </div>
          </div>
          <div className="container">
            <div className="mt-4 mb-4 form-container">
              <form>
                {step === 1 && (
                  <div className="form-step">
                    <h6 className="mb-4">Step 1: Basic Information</h6>
                    <div className="row m-0">
                      {/* MVR Downloaded Question */}
                      <label className="col-md-6 mb-3">
                        <span>
                          Have you downloaded the MVR (For Court Purpose) and
                          ready to upload?{" "}
                        </span>

                        {/* Radio buttons for Yes/No */}
                        <div
                          className="check_box_mvr"
                          style={{ marginTop: "10px" }}
                        >
                          <input
                            type="radio"
                            id="mvrYes"
                            name="mvrDownloaded"
                            value="yes"
                            checked={formData.mvrDownloaded === "yes"}
                            onChange={(e) => {
                              handleChange(e);
                              setErrors((prevErrors) => ({
                                ...prevErrors,
                                mvrDownloaded: "",
                              }));
                            }}
                          />
                          <label
                            htmlFor="mvrYes"
                            style={{ marginRight: "20px" }}
                          >
                            Yes
                          </label>

                          <input
                            type="radio"
                            id="mvrNo"
                            name="mvrDownloaded"
                            value="no"
                            checked={formData.mvrDownloaded === "no"}
                            onChange={(e) => {
                              handleChange(e);
                              setErrors((prevErrors) => ({
                                ...prevErrors,
                                mvrDownloaded: "",
                              }));
                            }}
                          />
                          <label htmlFor="mvrNo">No</label>
                        </div>

                        {errors.mvrDownloaded && (
                          <span className="error-message">
                            {errors.mvrDownloaded}
                          </span>
                        )}
                      </label>

                      {/* MVR File Upload */}

                      <label className="col-md-6 mb-3">
                        {formData.mvrDownloaded === "yes" && (
                          <>
                            <span>
                              Upload MVR For Court Purpose
                              <span style={{ color: "red" }}>*</span>
                            </span>
                            <input
                              type="file"
                              name="mvrFile"
                              accept=".pdf,.jpg,.jpeg,.png,.webp"
                              capture="environment" // Opens camera for image capture
                              onChange={(e) => {
                                // Allow all document types
                                const file = e.target.files[0];
                                if (file) {
                                  handleChange(e);
                                  setErrors((prevErrors) => ({
                                    ...prevErrors,
                                    mvrFile: "", // Clear errors if valid
                                  }));
                                }
                              }}
                              required
                            />
                            {errors.mvrFile && (
                              <span className="error-message">
                                {errors.mvrFile}
                              </span>
                            )}
                          </>
                        )}
                      </label>

                      <label key="firstName" className="col-md-6 mb-3">
                        <span>
                          First Name <span style={{ color: "red" }}>*</span>
                        </span>
                        <input
                          type="text"
                          name="firstName"
                          value={formData.firstName}
                          onChange={(e) => {
                            handleChange(e);
                            setErrors((prevErrors) => ({
                              ...prevErrors,
                              firstName: "",
                            }));
                          }}
                          onKeyPress={(e) => {
                            if (!/^[a-zA-Z]*$/.test(e.key)) {
                              e.preventDefault(); // Allow only alphabets
                            }
                          }}
                          maxLength={12}
                          required
                        />
                        {errors.firstName && (
                          <span className="error-message">
                            {errors.firstName}
                          </span>
                        )}
                      </label>

                      <label key="lastName" className="col-md-6 mb-3">
                        <span>
                          Last Name <span style={{ color: "red" }}>*</span>
                        </span>
                        <input
                          type="text"
                          name="lastName"
                          value={formData.lastName}
                          maxLength={12}
                          onChange={(e) => {
                            handleChange(e);
                            setErrors((prevErrors) => ({
                              ...prevErrors,
                              lastName: "",
                            }));
                          }}
                          onKeyPress={(e) => {
                            if (!/^[a-zA-Z]*$/.test(e.key)) {
                              e.preventDefault(); // Allow only alphabets
                            }
                          }}
                          required
                        />
                        {errors.lastName && (
                          <span className="error-message">
                            {errors.lastName}
                          </span>
                        )}
                      </label>

                      <label key="phone" className="col-md-6 mb-3">
                        <span>
                          Phone Number <span style={{ color: "red" }}>*</span>
                        </span>
                        <input
                          type="tel"
                          name="phone"
                          maxLength={10}
                          value={formData.phone}
                          onChange={(e) => {
                            handleChange(e);
                            setErrors((prevErrors) => ({
                              ...prevErrors,
                              phone: "",
                            }));
                          }}
                          onKeyPress={(e) => {
                            if (!/^[0-9]*$/.test(e.key)) {
                              e.preventDefault(); // Allow only numbers
                            }
                          }}
                          required
                        />
                        {errors.phone && (
                          <span className="error-message">{errors.phone}</span>
                        )}
                      </label>

                      <label key="email" className="col-md-6 mb-3">
                        <span>
                          Email <span style={{ color: "red" }}>*</span>
                        </span>
                        <input
                          type="email"
                          name="email"
                          maxLength={50}
                          value={formData.email}
                          onChange={(e) => {
                            handleChange(e);
                            setErrors((prevErrors) => ({
                              ...prevErrors,
                              email: "",
                            }));
                          }}
                          required
                          onKeyPress={(e) => {
                            const allowedChars = /^[a-zA-Z0-9@.]*$/;
                            if (!allowedChars.test(e.key)) {
                              e.preventDefault();
                            }
                          }}
                          onBlur={(e) => {
                            const emailPattern =
                              /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
                            if (!emailPattern.test(e.target.value)) {
                              setErrors({
                                ...errors,
                                email: "Please enter a valid email address",
                              });
                            }
                          }}
                        />
                        {errors.email && (
                          <span className="error-message">{errors.email}</span>
                        )}
                      </label>

                      {/* <label className="col-md-6 mb-3">
                        <span>
                          Date of Birth <span style={{ color: "red" }}>*</span>
                        </span>
                        <DatePicker
                          selected={formData.dob}
                          onChange={(date) => {
                            setFormData((prevData) => ({
                              ...prevData,
                              dob: date,
                            }));
                            setErrors((prevErrors) => ({
                              ...prevErrors,
                              dob: "",
                            }));
                          }}
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                          className="search-input datepicker"
                          maxDate={maxDob}
                        />
                        {errors.dob && (
                          <span className="error-message">{errors.dob}</span>
                        )}
                      </label> */}

                      {/* License Expiry */}
                      {/* <label className="col-md-6 mb-3">
                        <span>
                          Expiration of Driving License{" "}
                          <span style={{ color: "red" }}>*</span>
                        </span>
                        <DatePicker
                          selected={formData.licenseExpiry}
                          onChange={(date) => {
                            setFormData((prevData) => ({
                              ...prevData,
                              licenseExpiry: date,
                            }));
                            setErrors((prevErrors) => ({
                              ...prevErrors,
                              licenseExpiry: "",
                            }));
                          }}
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                          className="search-input datepicker"
                          minDate={new Date()}
                        />
                        {errors.licenseExpiry && (
                          <span className="error-message">
                            {errors.licenseExpiry}
                          </span>
                        )}
                      </label> */}

                      {/* Address */}
                      {/* <label>
                        <span>
                          Present Address{" "}
                          <span style={{ color: "red" }}>*</span>
                        </span>
                        <textarea
                          name="address"
                          style={{ height: "100px", resize: "none" }}
                          value={formData.address}
                          maxLength={250}
                          onChange={(e) => {
                            handleChange(e);
                            setErrors((prevErrors) => ({
                              ...prevErrors,
                              address: "",
                            }));
                          }}
                          required
                        ></textarea>
                        {errors.address && (
                          <span className="error-message">
                            {errors.address}
                          </span>
                        )}
                      </label> */}

                      <label className="col-md-12 mb-3">
                        <span>
                          Street Address <span style={{ color: "red" }}>*</span>
                        </span>

                        {/* Street Address */}
                        <input
                          type="text"
                          name="address"
                          placeholder="Street Address"
                          value={formData.address}
                          maxLength={100}
                          onChange={(e) => {
                            handleChange(e);
                            setErrors((prevErrors) => ({
                              ...prevErrors,
                              address: "",
                            }));
                          }}
                          required
                          style={{
                            display: "block",
                            width: "100%",
                            marginBottom: "10px",
                          }}
                        />
                        {errors.address && (
                          <span className="error-message">
                            {errors.address}
                          </span>
                        )}

                        {/* City */}
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            gap: "10px",
                          }}
                        >
                          <div className="col">
                            <input
                              type="text"
                              name="city"
                              placeholder="City"
                              value={formData.city}
                              maxLength={50}
                              onChange={(e) => {
                                handleChange(e);
                                setErrors((prevErrors) => ({
                                  ...prevErrors,
                                  city: "",
                                }));
                              }}
                              required
                              style={{
                                display: "block",
                                width: "100%",
                                marginBottom: "10px",
                              }}
                            />
                            {errors.city && (
                              <span className="error-message">
                                {errors.city}
                              </span>
                            )}
                          </div>

                          {/* State */}
                          <div className="col">
                            <select
                              name="drivingState"
                              value={formData.drivingState || ""}
                              onChange={(e) => {
                                handleChange(e);
                                if (errors.drivingState) {
                                  setErrors((prevErrors) => ({
                                    ...prevErrors,
                                    drivingState: "",
                                  }));
                                }
                              }}
                              required
                              style={{
                                backgroundColor: "#fff",
                                height: "50px",
                              }}
                            >
                              <option value="">Select a state</option>
                              {usStates.map((state) => (
                                <option key={state.name} value={state.name}>
                                  {state.name}
                                </option>
                              ))}
                            </select>
                            {errors.drivingState && (
                              <span style={{ color: "red" }}>
                                {errors.drivingState}
                              </span>
                            )}
                          </div>

                          {/* Zip Code */}
                          <div className="col">
                            <input
                              type="text"
                              name="zipcode"
                              placeholder="Zip Code"
                              value={formData.zipcode}
                              maxLength={5}
                              onChange={(e) => {
                                handleChange(e);
                                setErrors((prevErrors) => ({
                                  ...prevErrors,
                                  zipcode: "",
                                }));
                              }}
                              required
                              style={{ display: "block", width: "100%" }}
                            />
                            {errors.zipcode && (
                              <span className="error-message">
                                {errors.zipcode}
                              </span>
                            )}
                          </div>
                        </div>
                      </label>
                      {/* <label className="col-md-6 mb-3">
                        Driver's License number{" "}
                        <span style={{ color: "red" }}>*</span>
                        <input
                          type="text"
                          name="license"
                          value={formData.license}
                          maxLength={14}
                          onChange={(e) => {
                            handleAlphabetsNumberWithoutSpaceChange(e);
                            handleChange(e);
                            if (errors.license) {
                              setErrors((prevErrors) => ({
                                ...prevErrors,
                                license: "",
                              }));
                            }
                          }}
                          required
                        />
                        {errors.license && (
                          <span style={{ color: "red" }}>{errors.license}</span>
                        )}
                      </label> */}
                      <label className="col-md-6 mb-3">
                        <span>
                          Upload Drivers License{" "}
                          <span style={{ color: "red" }}>*</span>
                        </span>

                        <input
                          type="file"
                          name="driving_licence_image"
                          accept=".pdf,.jpg,.jpeg,.png,.webp"
                          onChange={(e) => {
                            const file = e.target.files[0];
                            if (file) {
                              handleChange(e);
                              setErrors((prevErrors) => ({
                                ...prevErrors,
                                driving_licence_image: "",
                              }));
                            }
                          }}
                          required
                        />
                        {errors.driving_licence_image && (
                          <span style={{ color: "red" }}>
                            {errors.driving_licence_image}
                          </span>
                        )}
                      </label>
                    </div>

                    {/* Submit Button */}
                    <div
                      className="submit-form-btn"
                      onClick={() => {
                        handleNext(); // Proceed to the next step
                      }}
                    >
                      Next
                    </div>
                  </div>
                )}

                {step === 2 && (
                  <div className="form-step ">
                    <h6 className="mb-4">Step 2: Rental Details</h6>
                    <div className="row m-0">
                      {/* <label className="col-md-6 mb-3">
                        Last 4 of SSN <span style={{ color: "red" }}>*</span>
                        <input
                          type="text"
                          name="ssn"
                          value={formData.ssn}
                          maxLength={4}
                          onChange={(e) => {
                            handleChange(e);
                            if (errors.ssn) {
                              setErrors((prevErrors) => ({
                                ...prevErrors,
                                ssn: "",
                              }));
                            }
                          }}
                          required
                        />
                        {errors.ssn && (
                          <span style={{ color: "red" }}>{errors.ssn}</span>
                        )}
                      </label> */}
                      {/* <label className="col-md-6 mb-3">
                        Select State <span style={{ color: "red" }}>*</span>
                        <select
                          name="licenseState"
                          value={formData.licenseState || ""}
                          onChange={(e) => {
                            handleChange(e);
                            if (errors.licenseState) {
                              setErrors((prevErrors) => ({
                                ...prevErrors,
                                licenseState: "",
                              }));
                            }
                          }}
                          required
                          style={{ backgroundColor: "#fff", height: "50px" }}
                        >
                          <option value="">Select a state</option>
                          {usStates.map((state) => (
                            <option key={state.name} value={state.name}>
                              {state.name}
                            </option>
                          ))}
                        </select>
                        {errors.licenseState && (
                          <span style={{ color: "red" }}>
                            {errors.licenseState}
                          </span>
                        )}
                      </label> */}

                      <label className="col-md-6 mb-3">
                        <span>
                          Are you approved by any rideshare / delivery platform
                          <span style={{ color: "red" }}>*</span>
                        </span>

                        {/* Radio buttons for Yes/No */}
                        <div
                          className="check_box_mvr"
                          style={{ marginTop: "10px" }}
                        >
                          <input
                            type="radio"
                            id="rideshareApproval"
                            name="rideshareApproval"
                            value="yes"
                            checked={formData.rideshareApproval === "yes"}
                            onChange={(e) => {
                              handleChange(e);
                              setErrors((prevErrors) => ({
                                ...prevErrors,
                                rideshareApproval: "",
                              }));
                            }}
                          />
                          <label
                            htmlFor="rideshareApproval"
                            style={{ marginRight: "20px" }}
                          >
                            Yes
                          </label>

                          <input
                            type="radio"
                            id="rideshareApproval"
                            name="rideshareApproval"
                            value="no"
                            checked={formData.rideshareApproval === "no"}
                            onChange={(e) => {
                              handleChange(e);
                              if (errors.rideshareApproval) {
                                setErrors((prevErrors) => ({
                                  ...prevErrors,
                                  rideshareApproval: "",
                                }));
                              }
                            }}
                          />
                          <label htmlFor="mvrNo">No</label>
                        </div>
                        {errors.rideshareApproval && (
                          <span style={{ color: "red" }}>
                            {errors.rideshareApproval}
                          </span>
                        )}
                      </label>

                      {/* <label className="col-md-6 mb-3">
                        Are you approved by Rideshare? Please mention which
                        ones. <span style={{ color: "red" }}>*</span>
                        <textarea
                          name="rideshareApproval"
                          value={formData.rideshareApproval}
                          onChange={(e) => {
                            handleChange(e);
                            if (errors.rideshareApproval) {
                              setErrors((prevErrors) => ({
                                ...prevErrors,
                                rideshareApproval: "",
                              }));
                            }
                          }}
                          required
                          style={{ resize: "none" }}
                        ></textarea>
                        {errors.rideshareApproval && (
                          <span style={{ color: "red" }}>
                            {errors.rideshareApproval}
                          </span>
                        )}
                      </label> */}
                      {formData.rideshareApproval === "yes" && (
                        <>
                          <label className="col-md-4 mb-3">
                            How many years of passenger-for-hire experience?{" "}
                            <span style={{ color: "red" }}>*</span>
                            <input
                              type="text"
                              name="hireExperience"
                              value={formData.hireExperience}
                              maxLength={2}
                              onInput={handleNumbersChange}
                              onChange={(e) => {
                                handleChange(e);
                                if (errors.hireExperience) {
                                  setErrors((prevErrors) => ({
                                    ...prevErrors,
                                    hireExperience: "",
                                  }));
                                }
                              }}
                              required
                            />
                            {errors.hireExperience && (
                              <span style={{ color: "red" }}>
                                {errors.hireExperience}
                              </span>
                            )}
                          </label>
                          <label className="col-md-12 mb-3">
                            <span className="subLabel">
                              Approved and ready to work for (Select all that
                              apply)
                              <span style={{ color: "red" }}>*</span>
                            </span>
                            <div>
                              <label>
                                <input
                                  type="checkbox"
                                  name="workApproval"
                                  value="Uber"
                                  checked={formData.workApproval.includes(
                                    "Uber"
                                  )}
                                  onChange={(e) => {
                                    handleChange(e);
                                    if (errors.workApproval) {
                                      setErrors((prevErrors) => ({
                                        ...prevErrors,
                                        workApproval: "",
                                      }));
                                    }
                                  }}
                                />
                                Uber
                              </label>
                              <label>
                                <input
                                  type="checkbox"
                                  name="workApproval"
                                  value="GIG Work"
                                  checked={formData.workApproval.includes(
                                    "GIG Work"
                                  )}
                                  onChange={(e) => {
                                    handleChange(e);
                                    if (errors.workApproval) {
                                      setErrors((prevErrors) => ({
                                        ...prevErrors,
                                        workApproval: "",
                                      }));
                                    }
                                  }}
                                />
                                GIG Work
                              </label>
                              <label>
                                <input
                                  type="checkbox"
                                  name="workApproval"
                                  value="Lyft"
                                  checked={formData.workApproval.includes(
                                    "Lyft"
                                  )}
                                  onChange={(e) => {
                                    handleChange(e);
                                    if (errors.workApproval) {
                                      setErrors((prevErrors) => ({
                                        ...prevErrors,
                                        workApproval: "",
                                      }));
                                    }
                                  }}
                                />
                                Lyft
                              </label>
                              <label>
                                <input
                                  type="checkbox"
                                  name="workApproval"
                                  value="Other"
                                  checked={formData.workApproval.includes(
                                    "Other"
                                  )}
                                  onChange={(e) => {
                                    handleChange(e);
                                    if (errors.workApproval) {
                                      setErrors((prevErrors) => ({
                                        ...prevErrors,
                                        workApproval: "",
                                      }));
                                    }
                                  }}
                                />
                                Other
                              </label>
                              {formData.workApproval.includes("Other") && (
                                <input
                                  type="text"
                                  name="workApprovalOther"
                                  placeholder="Specify other platform"
                                  value={formData.workApprovalOther}
                                  onChange={handleChange}
                                />
                              )}
                            </div>
                            {errors.workApproval && (
                              <span style={{ color: "red" }}>
                                {errors.workApproval}
                              </span>
                            )}
                          </label>
                        </>
                      )}
                      {formData.rideshareApproval === "no" && (
                        <label className="col-md-12 mb-3">
                          <span className="subLabel">
                            Using car for (Select all that apply){" "}
                            <span style={{ color: "red" }}>*</span>
                          </span>
                          <div>
                            <label>
                              <input
                                type="checkbox"
                                name="carUsage"
                                value="Rideshare"
                                checked={formData.carUsage.includes(
                                  "Rideshare"
                                )}
                                onChange={(e) => {
                                  handleChange(e);
                                  if (errors.carUsage) {
                                    setErrors((prevErrors) => ({
                                      ...prevErrors,
                                      carUsage: "",
                                    }));
                                  }
                                }}
                              />
                              Rideshare
                            </label>
                            <label>
                              <input
                                type="checkbox"
                                name="carUsage"
                                value="Commute to work"
                                checked={formData.carUsage.includes(
                                  "Commute to work"
                                )}
                                onChange={(e) => {
                                  handleChange(e);
                                  if (errors.carUsage) {
                                    setErrors((prevErrors) => ({
                                      ...prevErrors,
                                      carUsage: "",
                                    }));
                                  }
                                }}
                              />
                              Commute to work
                            </label>
                            <label>
                              <input
                                type="checkbox"
                                name="carUsage"
                                value="Other"
                                checked={formData.carUsage.includes("Other")}
                                onChange={(e) => {
                                  handleChange(e);
                                  if (errors.carUsage) {
                                    setErrors((prevErrors) => ({
                                      ...prevErrors,
                                      carUsage: "",
                                    }));
                                  }
                                }}
                              />
                              Other
                            </label>
                            {formData.carUsage.includes("Other") && (
                              <input
                                type="text"
                                name="carUsageOther"
                                placeholder="Specify other usage"
                                value={formData.carUsageOther}
                                onChange={handleChange}
                              />
                            )}
                          </div>
                          {errors.carUsage && (
                            <span style={{ color: "red" }}>
                              {errors.carUsage}
                            </span>
                          )}
                        </label>
                      )}

                      <label className="col-md-12 mb-3">
                        <span className="subLabel">
                          When do you need the car?{" "}
                          <span style={{ color: "red" }}>*</span>
                        </span>
                        <div className="radio-group">
                          <label>
                            <input
                              type="radio"
                              name="rentalStart"
                              value="Within 1-2 days"
                              checked={formData.rentalStart.includes(
                                "Within 1-2 days"
                              )}
                              onChange={(e) => {
                                handleChange(e);
                                if (errors.rentalStart) {
                                  setErrors((prevErrors) => ({
                                    ...prevErrors,
                                    rentalStart: "",
                                  }));
                                }
                              }}
                            />
                            Within 1-2 days
                          </label>
                          <label>
                            <input
                              type="radio"
                              name="rentalStart"
                              value="Within 2-3 days"
                              checked={formData.rentalStart.includes(
                                "Within 2-3 days"
                              )}
                              onChange={(e) => {
                                handleChange(e);
                                if (errors.rentalStart) {
                                  setErrors((prevErrors) => ({
                                    ...prevErrors,
                                    rentalStart: "",
                                  }));
                                }
                              }}
                            />
                            Within 2-3 days
                          </label>
                          <label>
                            <input
                              type="radio"
                              name="rentalStart"
                              value="Within 4-7 days"
                              checked={formData.rentalStart.includes(
                                "Within 4-7 days"
                              )}
                              onChange={(e) => {
                                handleChange(e);
                                if (errors.rentalStart) {
                                  setErrors((prevErrors) => ({
                                    ...prevErrors,
                                    rentalStart: "",
                                  }));
                                }
                              }}
                            />
                            Within 4-7 days
                          </label>
                        </div>
                        {errors.rentalStart && (
                          <span style={{ color: "red" }}>
                            {errors.rentalStart}
                          </span>
                        )}
                      </label>
                      {/* <h6>Emergency Contact 1</h6>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          flexWrap: "wrap",
                          gap: "15px",
                        }}
                        className="col-md-12 mb-3 mt-2"
                      >
                        <div className="col-md-3">
                          {" "}
                          <label className="label768">
                            Name
                            <input
                              type="text"
                              name="name"
                              value={formData.emergencyContact1.name}
                              onChange={(e) => handleEmergencyChange(e, 1)}
                            />
                            {errors.emergencyContact1_name && (
                              <span className="error">
                                {errors.emergencyContact1_name}
                              </span>
                            )}
                          </label>
                        </div>
                        <div className="col-md-3">
                          {" "}
                          <label className="label768">
                            Relation
                            <input
                              type="text"
                              name="relation"
                              value={formData.emergencyContact1.relation}
                              onChange={(e) => handleEmergencyChange(e, 1)}
                            />
                            {errors.emergencyContact1_relation && (
                              <span className="error">
                                {errors.emergencyContact1_relation}
                              </span>
                            )}
                          </label>
                        </div>
                        <div className="col-md-3">
                          {" "}
                          <label className="label768">
                            Phone
                            <input
                              type="tel"
                              name="phone"
                              value={formData.emergencyContact1.phone}
                              onChange={(e) => handleEmergencyChange(e, 1)}
                              maxLength={10}
                            />
                            {errors.emergencyContact1_phone && (
                              <span className="error">
                                {errors.emergencyContact1_phone}
                              </span>
                            )}
                          </label>
                        </div>
                      </div>

                      <h6>Emergency Contact 2</h6>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          flexWrap: "wrap",
                          gap: "15px",
                        }}
                        className="col-md-12 mb-3 mt-2"
                      >
                        <div className="col-md-3">
                          {" "}
                          <label className="label768">
                            Name
                            <input
                              type="text"
                              name="name"
                              value={formData.emergencyContact2.name}
                              onChange={(e) => handleEmergencyChange(e, 2)}
                            />
                            {errors.emergencyContact2_name && (
                              <span className="error">
                                {errors.emergencyContact2_name}
                              </span>
                            )}
                          </label>
                        </div>
                        <div className="col-md-3">
                          {" "}
                          <label className="label768">
                            Relation
                            <input
                              type="text"
                              name="relation"
                              value={formData.emergencyContact2.relation}
                              onChange={(e) => handleEmergencyChange(e, 2)}
                            />
                            {errors.emergencyContact2_relation && (
                              <span className="error">
                                {errors.emergencyContact2_relation}
                              </span>
                            )}
                          </label>
                        </div>
                        <div className="col-md-3">
                          {" "}
                          <label className="label768">
                            Phone
                            <input
                              type="tel"
                              name="phone"
                              value={formData.emergencyContact2.phone}
                              onChange={(e) => handleEmergencyChange(e, 2)}
                              maxLength={10}
                            />
                            {errors.emergencyContact2_phone && (
                              <span className="error">
                                {errors.emergencyContact2_phone}
                              </span>
                            )}
                          </label>
                        </div>
                      </div> */}

                      {error && <p style={{ color: "red" }}>{error}</p>}
                      <h6>Signature</h6>
                      <div className="signature-section mt-4 col-md-12 mb-3">
                        {/* <h5>Signature</h5> */}
                        <SignatureCanvas
                          ref={signaturePad}
                          penColor="black"
                          canvasProps={{
                            height: 200,
                            width: 300,
                            className: "signature-canvas border rounded",
                          }}
                        />
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            gap: "10px",
                            alignItems: "center",
                            justifyContent: "flex-start",
                          }}
                        >
                          <div
                            // type="button"
                            className="submit-form-btn-small"
                            onClick={handleClearSignature}
                          >
                            Clear
                          </div>
                          <div
                            // type="button"
                            className="submit-form-btn-small"
                            onClick={handleSaveSignature}
                          >
                            Save Signature
                          </div>
                        </div>
                        {errors.signatureImage && (
                          <span style={{ color: "red" }}>
                            {errors.signatureImage}
                          </span>
                        )}
                      </div>
                    </div>

                    {/* Checkbox for agreement */}
                    {/* <div className="box-remember-forgot">
                      <div className="form-group">
                        <div className="remeber-me">
                          <label className="text-sm-medium neutral-500">
                            <input
                              name="agreed"
                              className="cb-remember"
                              type="checkbox"
                              checked={agreed}
                              onChange={handleCheckboxChange}
                            />{" "}
                            Agree to our{" "}
                            <a
                              className="text-sm-medium neutral-1000"
                              href="/terms_of_use"
                            >
                              Terms of service
                            </a>{" "}
                            and{" "}
                            <a
                              className="text-sm-medium neutral-1000"
                              href="/privacy_policy"
                            >
                              Privacy Policy
                            </a>{" "}
                            for Agreement to Rent Vehicle
                          </label>
                        </div>
                      </div>
                    </div> */}
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "10px",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <div className="submit-form-btn" onClick={handleBack}>
                        Back
                      </div>
                      {/* <div
                        className={`submit-form-btn ${
                          !agreed ? "disabled" : ""
                        }`}
                        onClick={agreed ? handleSubmit : undefined}
                        style={{
                          cursor: agreed ? "pointer" : "not-allowed",
                        }}
                      >
                        Submit
                      </div> */}
                      <div
                        className="submit-form-btn"
                        onClick={handleSubmitImg}
                      >
                        Submit
                      </div>
                    </div>
                  </div>
                )}
              </form>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default AplicationForm;
