import DOMPurify from "dompurify";
import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Slider from "react-slick";
import diesel from "../../Assests/imgs/page/car/diesel.svg";
import km from "../../Assests/imgs/page/car/km.svg";
import seat from "../../Assests/imgs/page/car/seat.svg";
import calender from "../../Assests/imgs/page/homepage2/newCal.svg";
import {
  capitalizeFirstLetter,
  handleError,
} from "../../CommonJquery/CommonJquery";
import { retrieveData, storeData } from "../../LocalConnection/LocalConnection";
import {
  APL_LINK,
  details_cars_website,
  server_post_data,
} from "../../ServiceConnection/serviceconnection";
import Layout from "../Layout/Layout";
import Reviews from "../Sections/Reviews";

import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
const SlickArrowLeft = ({ currentSlide, slideCount, ...props }) => (
  <button
    {...props}
    className={
      "slick-prev slick-arrow" + (currentSlide === 0 ? " slick-disabled" : "")
    }
    type="button"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M7.99992 3.33325L3.33325 7.99992M3.33325 7.99992L7.99992 12.6666M3.33325 7.99992H12.6666"
        stroke=""
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>
    </svg>
  </button>
);
const SlickArrowRight = ({ currentSlide, slideCount, ...props }) => (
  <button
    {...props}
    className={
      "slick-next slick-arrow" +
      (currentSlide === slideCount - 1 ? " slick-disabled" : "")
    }
    type="button"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M7.99992 12.6666L12.6666 7.99992L7.99992 3.33325M12.6666 7.99992L3.33325 7.99992"
        stroke=""
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        {" "}
      </path>
    </svg>
  </button>
);
const CarDetail = () => {
  const navigate = useNavigate();
  const customer_id = retrieveData("customer_id");
  const [isOpen, setOpen] = useState(false);
  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);
  const [slider1, setSlider1] = useState(null);
  const [slider2, setSlider2] = useState(null);
  const [showLoaderAdmin, setShowLoaderAdmin] = useState(false);
  const location = useLocation();
  const currentUrl = location.pathname.substring(1);
  const [imgLink, setImgLink] = useState();
  const [carDetails, setCarDetails] = useState([]);
  const [images, setImages] = useState([]);
  const [reviews, setReviews] = useState([]);
  const [faq, setFaq] = useState([]);
  const [startDate, setStartDate] = useState(() => {
    const storedDetails = localStorage.getItem("vehicleSearchDetails");
    return storedDetails
      ? new Date(JSON.parse(storedDetails).pickupDate)
      : new Date();
  });

  const [timePeriod, setTimePeriod] = useState(() => {
    const storedDetails = localStorage.getItem("vehicleSearchDetails");
    return storedDetails ? JSON.parse(storedDetails).timePeriod : "1 Days";
  });
  const [endDate, setEndDate] = useState(
    new Date(new Date().setDate(new Date().getDate() + 1))
  );
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [NoOfDays, setNoOfDays] = useState("");
  const [show, setShow] = useState(false);

  useEffect(() => {
    const existingSearchDetails = JSON.parse(
      localStorage.getItem("vehicleSearchDetails") || "{}"
    );

    const updatedSearchDetails = {
      ...existingSearchDetails,
      pickupDate: startDate.toISOString(),
      timePeriod: timePeriod,
    };

    localStorage.setItem(
      "vehicleSearchDetails",
      JSON.stringify(updatedSearchDetails)
    );
  }, [startDate, timePeriod]);

  useEffect(() => {
    master_data_get_details();
  }, [currentUrl]);
  console.log(currentUrl);
  useEffect(() => {
    setNav1(slider1);
    setNav2(slider2);
  }, [slider2, slider1]);

  const settingsMain = {
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: false,
    arrows: true,
    fade: false,
    prevArrow: <SlickArrowLeft />,
    nextArrow: <SlickArrowRight />,
  };

  const settingsThumbs = {
    slidesToShow: 6,
    slidesToScroll: 1,
    infinite: false,
    asNavFor: nav1,
    dots: false,
    focusOnSelect: true,
    vertical: false,
    responsive: [
      { breakpoint: 1200, settings: { slidesToShow: 5 } },
      { breakpoint: 1024, settings: { slidesToShow: 4 } },
      { breakpoint: 700, settings: { slidesToShow: 4 } },
      { breakpoint: 480, settings: { slidesToShow: 3 } },
      { breakpoint: 380, settings: { slidesToShow: 2 } },
    ],
  };

  const [isAccordion, setIsAccordion] = useState(null);

  const handleAccordion = (key) => {
    setIsAccordion((prevState) => (prevState === key ? null : key));
  };

  const master_data_get_details = async () => {
    setShowLoaderAdmin(true);
    const fd = new FormData();
    fd.append("current_url", "/" + currentUrl);
    await server_post_data(details_cars_website, fd)
      .then((Response) => {
        if (Response.error) {
          handleError(Response.message);
        } else {
          if (Response.message.car_data.length > 0) {
            setCarDetails(Response.message.car_data[0]);
          }
          console.log(Response.message.car_data[0]);
          setImgLink(APL_LINK + Response.message.image_link);
          setImages(Response.message.multiimage);
          setReviews(Response.message.reviews);
          setFaq(Response.message.faq);
          console.log(Response.message.reviews);
        }
        setShowLoaderAdmin(false);
      })
      .catch((error) => {
        console.log(error);
        setShowLoaderAdmin(false);
      });
  };

  const features = [
    {
      src: km,
      alt: "Carento",
      label:  "Unlimited Miles",
    },
    {
      src: diesel,
      alt: "Carento",
      label: carDetails.fuel_type,
    },
    // {
    //   src: auto,
    //   alt: "Carento",
    //   label: carDetails.gear_type,
    // },
    {
      src: seat,
      alt: "Carento",
      label: carDetails.seating_capacity + " " + "Seats",
    },
    {
      src: calender,
      alt: "Carento",
      label:  "Make" + " " +  carDetails.make_year ,
    },
    // {
    //   src: bag,
    //   alt: "Carento",
    //   label: carDetails.storage_capacity + " " + "liters",
    // },
    // { src: suv, alt: "Carento", label: carDetails.model_name },
    // { src: door, alt: "Carento", label: carDetails.no_of_door + " " + "Doors" },
    // { src: lit, alt: "Carento", label: carDetails.car_mileage + " " + "k/l" },
  ];

  const generateTimeSlots = () => {
    const times = [];
    let startTime = new Date();
    startTime.setHours(0, 30, 0, 0); // Start at 12:30 AM

    for (let i = 0; i < 48; i++) {
      let time = startTime.toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      });

      // Force uppercase for AM/PM
      time = time.replace("am", "AM").replace("pm", "PM");
      times.push(time);

      startTime.setMinutes(startTime.getMinutes() + 30); // Increment by 30 minutes
    }

    return times;
  };
  const timeSlots = generateTimeSlots();

  const handleTimeChange = (event) => {
    setStartTime(event.target.value);
  };

  const handleTimeEnd = (event) => {
    setEndTime(event.target.value);
  };

  const handleClose = () => setShow(false);
  const handleNavigate = () => {
    localStorage.setItem("appli_link_path", "appli_link_path");
    storeData("current_data", JSON.stringify({
      car_id: carDetails.primary_id,
      car_price: carDetails.Price,
      fromDate: startDate,
      endDate: endDate,
      pickup_time: startTime,
      drop_time: endTime,
      NoOfDays: timePeriod,
    }));
    if (!customer_id || customer_id === "0") {
      navigate("/login");
    } else {
      navigate("/application_form", {
      });
    }
  };

  return (
    <Layout>
      <div>
        <section className="box-section box-breadcrumb background-body">
          <div className="container">
            <ul className="breadcrumbs">
              <li>
                <Link to="/">Home</Link>
                <span className="arrow-right">
                  <svg
                    width={7}
                    height={12}
                    viewBox="0 0 7 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1 11L6 6L1 1"
                      stroke="currentColor"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </span>
              </li>
              <li>
                <Link to="/listing">Car Listings</Link>
                <span className="arrow-right">
                  <svg
                    width={7}
                    height={12}
                    viewBox="0 0 7 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1 11L6 6L1 1"
                      stroke="currentColor"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </span>
              </li>
              <li>
                <span className="text-breadcrumb">{carDetails.car_name}</span>
              </li>
            </ul>
          </div>
        </section>
        <section className="box-section box-content-tour-detail background-body pt-0">
          <div className="container">
            <div className="tour-header">
              {/* <div className="tour-rate">
                <div className="rate-element">
                  <span className="rating">
                    {carDetails.review}{" "}
                    <span className="text-sm-medium neutral-500">
                      ({carDetails.total_review} reviews)
                    </span>
                  </span>
                </div>
              </div> */}
              <div className="row">
                <div className="col-lg-8">
                  <div className="tour-title-main">
                    <h4 className="neutral-1000">
                      {carDetails.car_name} - {carDetails.car_tagline}
                    </h4>
                  </div>
                </div>
              </div>
              <div className="tour-metas">
                <div className="tour-meta-left">
                  <p className="text-md-medium neutral-1000 mr-20 tour-location">
                    <svg
                      className="invert"
                      xmlns="http://www.w3.org/2000/svg"
                      width={16}
                      height={16}
                      viewBox="0 0 16 16"
                      fill="none"
                    >
                      <path
                        d="M7.99967 0C4.80452 0 2.20508 2.59944 2.20508 5.79456C2.20508 9.75981 7.39067 15.581 7.61145 15.8269C7.81883 16.0579 8.18089 16.0575 8.38789 15.8269C8.60867 15.581 13.7943 9.75981 13.7943 5.79456C13.7942 2.59944 11.1948 0 7.99967 0ZM7.99967 8.70997C6.39211 8.70997 5.0843 7.40212 5.0843 5.79456C5.0843 4.187 6.39214 2.87919 7.99967 2.87919C9.6072 2.87919 10.915 4.18703 10.915 5.79459C10.915 7.40216 9.6072 8.70997 7.99967 8.70997Z"
                        fill="#101010"
                      />
                    </svg>
                    {carDetails.car_map_address}
                  </p>
                  <a
  className="text-md-medium neutral-1000 mr-20"
  href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(carDetails.car_map_address)}`}
  target="_blank"
  rel="noopener noreferrer"
>
  Show on map
</a>

                  <div className="rate-element">
                    <span className="rating">
                      {carDetails.review}{" "}
                      <span className="text-sm-medium neutral-500">
                        ({carDetails.total_review} reviews)
                      </span>
                    </span>
                  </div>
                </div>
                <div className="tour-meta-right">
                  <Link className="btn btn-share" href="#">
                    <svg
                      width={16}
                      height={18}
                      viewBox="0 0 16 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M13 11.5332C12.012 11.5332 11.1413 12.0193 10.5944 12.7584L5.86633 10.3374C5.94483 10.0698 6 9.79249 6 9.49989C6 9.10302 5.91863 8.72572 5.77807 8.37869L10.7262 5.40109C11.2769 6.04735 12.0863 6.46655 13 6.46655C14.6543 6.46655 16 5.12085 16 3.46655C16 1.81225 14.6543 0.466553 13 0.466553C11.3457 0.466553 10 1.81225 10 3.46655C10 3.84779 10.0785 4.20942 10.2087 4.54515L5.24583 7.53149C4.69563 6.90442 3.8979 6.49989 3 6.49989C1.3457 6.49989 0 7.84559 0 9.49989C0 11.1542 1.3457 12.4999 3 12.4999C4.00433 12.4999 4.8897 11.9996 5.4345 11.2397L10.147 13.6529C10.0602 13.9331 10 14.2249 10 14.5332C10 16.1875 11.3457 17.5332 13 17.5332C14.6543 17.5332 16 16.1875 16 14.5332C16 12.8789 14.6543 11.5332 13 11.5332Z"
                        fill="currentColor"
                      />
                    </svg>
                    Share
                  </Link>
                  <Link className="btn btn-wishlish" to="/favourite">
                    <svg
                      width={20}
                      height={18}
                      viewBox="0 0 20 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M2.2222 2.3638C4.34203 0.243977 7.65342 0.0419426 10.0004 1.7577C12.3473 0.0419426 15.6587 0.243977 17.7786 2.3638C20.1217 4.70695 20.1217 8.50594 17.7786 10.8491L12.1217 16.5059C10.9501 17.6775 9.05063 17.6775 7.87906 16.5059L2.2222 10.8491C-0.120943 8.50594 -0.120943 4.70695 2.2222 2.3638Z"
                        fill="currentColor"
                      />
                    </svg>
                    Wishlist
                  </Link>
                </div>
              </div>
            </div>
            <div className="row sticky0">
              <div className="col-lg-8">
                <div className="container-banner-activities">
                  <div className="box-banner-activities">
                    <Slider
                      {...settingsMain}
                      asNavFor={slider2}
                      ref={(slider) => setSlider1(slider)}
                      className="banner-activities-detail"
                    >
                      {images.map((banner, index) => (
                        <div
                          className="banner-slide-activity height400"
                          key={index}
                        >
                          <img
                            style={{
                              width: "100%",
                              height: "100%",
                              objectFit: "cover",
                            }}
                            src={imgLink + banner.image}
                            alt="Rapid Car Leasing"
                          />
                        </div>
                      ))}
                    </Slider>
                  </div>
                  <div className="slider-thumnail-activities">
                    <Slider
                      {...settingsThumbs}
                      asNavFor={slider1}
                      ref={(slider) => setSlider2(slider)}
                      className="slider-nav-thumbnails-activities-detail"
                    >
                      {images.map((banner, index) => (
                        <div
                          className="banner-slide"
                          style={{ cursor: "pointer" }}
                          key={index}
                        >
                          <LazyLoadImage
                            className="imgBnnrCarDetail"
                            src={imgLink + banner.image}
                            alt="Rapid Car Leasing"
                            effect="blur"
                          />
                        </div>
                      ))}
                    </Slider>
                  </div>
                  <div className="box-feature-car">
                    <div className="list-feature-car align-items-start">
                      {features.map((feature, index) => (
                        <div className="item-feature-car w-md-25" key={index}>
                          <div className="item-feature-car-inner">
                            <div className="feature-image">
                              <img src={feature.src} alt={feature.alt} />
                            </div>
                            <div className="feature-info">
                              <p className="text-md-medium neutral-1000">
                                {feature.label === "cng" ? (
                                  "CNG"
                                ) : (
                                  <>
                                    {feature &&
                                      feature.label &&
                                      feature.label.length > 0 &&
                                      capitalizeFirstLetter(feature.label)}
                                  </>
                                )}
                              </p>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="box-collapse-expand">
                    <div className="group-collapse-expand">
                      <button
                        className={
                          isAccordion === 1
                            ? "btn btn-collapse collapsed"
                            : "btn btn-collapse"
                        }
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseOverview"
                        aria-expanded="false"
                        aria-controls="collapseOverview"
                        onClick={() => handleAccordion(1)}
                      >
                        <h6>Overview</h6>
                        <svg
                          width={12}
                          height={7}
                          viewBox="0 0 12 7"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M1 1L6 6L11 1"
                            stroke=""
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            fill="none"
                          />
                        </svg>
                      </button>
                      <div
                        className={
                          isAccordion === 1 ? "collapse" : "collapse show"
                        }
                        id="collapseOverview"
                      >
                        <div className="card card-body">
                          <p
                            dangerouslySetInnerHTML={{
                              __html: DOMPurify.sanitize(
                                carDetails.car_description
                              ),
                            }}
                          ></p>
                        </div>
                      </div>
                    </div>
                    {/* <div className="group-collapse-expand">
                      <button
                        className={
                          isAccordion === 2
                            ? "btn btn-collapse collapsed"
                            : "btn btn-collapse"
                        }
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseItinerary"
                        aria-expanded="false"
                        aria-controls="collapseItinerary"
                        onClick={() => handleAccordion(2)}
                      >
                        <h6>Included in the price</h6>
                        <svg
                          width={12}
                          height={7}
                          viewBox="0 0 12 7"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M1 1L6 6L11 1"
                            stroke=""
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            fill="none"
                          />
                        </svg>
                      </button>
                      <div
                        className={
                          isAccordion === 2 ? "collapse" : "collapse show"
                        }
                        id="collapseItinerary"
                      >
                        <div className="card card-body">
                          <ul className="list-checked-green">
                            <li>
                              Free cancellation up to 48 hours before pick-up
                            </li>
                            <li>
                              Collision Damage Waiver with $700 deductible
                            </li>
                            <li>Theft Protection with ₫66,926,626 excess</li>
                            <li>Unlimited mileage</li>
                          </ul>
                        </div>
                      </div>
                    </div> */}
                    <div className="group-collapse-expand">
                      <button
                        className={
                          isAccordion === 3
                            ? "btn btn-collapse collapsed"
                            : "btn btn-collapse"
                        }
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseQuestion"
                        aria-expanded="false"
                        aria-controls="collapseQuestion"
                        onClick={() => handleAccordion(3)}
                      >
                        <h6>FAQs</h6>
                        <svg
                          width={12}
                          height={7}
                          viewBox="0 0 12 7"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M1 1L6 6L11 1"
                            stroke=""
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            fill="none"
                          />
                        </svg>
                      </button>
                      <div
                        className={
                          isAccordion === 3 ? "collapse" : "collapse show"
                        }
                        id="collapseQuestion"
                      >
                        <div className="card card-body">
                          <div className="list-questions">
                            {faq &&
                              faq.length > 0 &&
                              faq.map((faq, index) => (
                                <div className="item-question" key={index}>
                                  <div className="head-question">
                                    <p className="text-md-bold neutral-1000">
                                      {faq.question}
                                    </p>
                                  </div>
                                  <div className="content-question">
                                    <p className="text-sm-medium neutral-800">
                                      {faq.answer}
                                    </p>
                                  </div>
                                </div>
                              ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <Reviews
                    reviews={reviews}
                    total={carDetails.review}
                    price={carDetails.price_ratting}
                    service={carDetails.service}
                    safety={carDetails.safety}
                    entertainment={carDetails.entertainment}
                    accessibility={carDetails.accessibility}
                    support={carDetails.support}
                    total_review={carDetails.total_review}
                  />
                </div>
              </div>
              <div className="col-lg-4 bookingEngineColumn">
                <div className="booking-form">
                  <div className="head-booking-form">
                    <p className="text-xl-bold neutral-1000"></p>
                  </div>
                  <div className="content-booking-form">
                    <div className="border-bottom-0 pb-0 mb-3">
                      {/* <strong
                        className="text-md-bold neutral-1000"
                        style={{ whiteSpace: "nowrap" }}
                      >
                        Pick-Up Date & Time
                      </strong> */}
                      <strong
                        className="text-md-bold neutral-1000"
                        style={{ whiteSpace: "nowrap" }}
                      >
                        Pick-Up Date
                      </strong>
                      <div
                        className="input-calendar car_detail_date_picker"
                        style={{ display: "flex", gap: "0.5rem" }}
                      >
                        <DatePicker
                          selected={startDate}
                          onChange={(date) => setStartDate(date)}
                          className={`
                                      search-input datepicker
                                    W-100`}
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                          minDate={new Date()}
                        />
                        {/* <select
                          className="search-input datepicker"
                          value={startTime}
                          onChange={handleTimeChange}
                        >
                          {timeSlots.map((time, index) => (
                            <option key={index} value={time}>
                              {time}
                            </option>
                          ))}
                        </select> */}
                      </div>
                    </div>
                    <div className="mb-3">
                      <strong
                        className="text-md-bold neutral-1000"
                        style={{ whiteSpace: "nowrap" }}
                      >
                        Select Time Period
                      </strong>
                      <div
                        className="input-calendar"
                        style={{ display: "flex", gap: "0.5rem" }}
                      >
                        {/* <DatePicker
                          selected={endDate}
                          onChange={(date) => {
                            setEndDate(date);
                          }}
                          className={`
                                      search-input datepicker
                                    `}
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                          minDate={
                            new Date(
                              new Date().setDate(new Date().getDate() + 1)
                            )
                          }
                        /> */}
                        {/* <select
                          className="search-input datepicker"
                          value={endTime}
                          onChange={handleTimeEnd}
                        >
                          {timeSlots.map((time, index) => (
                            <option key={index} value={time}>
                              {time}
                            </option>
                          ))}
                        </select> */}{" "}
                        <select
                          className="form-select "
                          value={timePeriod}
                          onChange={(e) => setTimePeriod(e.target.value)}
                        >
                          <option value="Few Days">Few days</option>
                          <option value="Few Week">Few Week</option>
                          <option value="Few Month">Few Month</option>
                        </select>
                      </div>
                    </div>

                    <div className="box-button-book">
                      <button className="btn btn-book" onClick={handleNavigate}>
                        Book Now
                        <svg
                          width={16}
                          height={16}
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M8 15L15 8L8 1M15 8L1 8"
                            stroke="currentColor"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </button>
                    </div>

                    <div className="box-need-help">
                      <Link href="#">Need some help?</Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Login Required</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          You must be logged in to book a car. Please log in to continue.
        </Modal.Body>
        <Modal.Footer>
          <Button className="bg-light" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              navigate("/login");
              setShow(false);
            }}
          >
            Login
          </Button>
        </Modal.Footer>
      </Modal>
    </Layout>
  );
};

export default CarDetail;
